import { Link } from 'gatsby'
import get from 'lodash/get'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { I18nConsumer } from '@components/context/I18nContext'

type Props = {
  className?: string
}

const LocaleSelector = ({ className }: Props) => {
  const { i18n } = useTranslation()

  return (
    <I18nConsumer>
      {(context) => {
        const lang = get(context, `lang`, i18n.language)
        const otherLang = lang === `en` ? `fr` : `en`
        const to = get(context, [`paths`, otherLang]) || `/${otherLang}`

        return (
          <Link
            className={className}
            onClick={() => {
              i18n.changeLanguage(otherLang)
            }}
            to={to}
          >
            {otherLang.toUpperCase()}
          </Link>
        )
      }}
    </I18nConsumer>
  )
}

export default LocaleSelector
