import React from 'react'
import get from 'lodash/get'
import isFunction from 'lodash/isFunction'
// import * as Sentry from '@sentry/browser'

// Sentry.init({
//   dsn: `https://70def316168f460aaa22438f93761402@sentry.io/1460094`,
//   // Optional settings, see https://docs.sentry.io/clients/node/config/#optional-settings
//   environment: process.env.NODE_ENV,
//   enabled: (() => [`production`, `stage`].indexOf(process.env.NODE_ENV) !== -1)(),
// })

import ErrorBoundary from '@components/layout/ErrorBoundary'

export const wrapRootElement = ({ element }) => <ErrorBoundary>{element}</ErrorBoundary>

if (window) {
  // When accessing /fr/recherche-iframe, force to black background:
  if (/-iframe/i.test(window.location.href)) {
    document.body.style.backgroundColor = `#000`
  }

  window.onbeforeprint = () => {
    const hideWidget = get(window, `Tawk_API.hideWidget`)
    if (isFunction(hideWidget)) {
      hideWidget()
    }
  }
  window.onafterprint = () => {
    const showWidget = get(window, `Tawk_API.showWidget`)
    if (isFunction(showWidget)) {
      showWidget()
    }
  }
}
