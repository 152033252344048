const defaultTheme = require(`tailwindcss/defaultTheme`)
const colors = require(`tailwindcss/colors`)
const svgToDataUri = require(`mini-svg-data-uri`)

module.exports = {
  theme: {
    borderRadius: {
      ...defaultTheme.borderRadius,
      '2xl2': `1.25rem`,
      xs: `0.5rem`,
      lg: `20px`,
      sm: `8px`,
    },
    colors: {
      transparent: `transparent`,

      overlay: `rgba(38,50,56,.7)`,

      black: `#000`,
      blackLight: `#000`,
      white: `#fff`,

      brandColor: `#ea4765`, // pink.500
      textColor: `#3e3e3e`,
      linkColor: `#4A90E2`, // blue.500
      hoverColor: `#ea4765`, // pink.500
      errorColor: `#c53030`, // red.700

      gray: {
        100: `#f7f7f7`,
        200: `#ebebeb`,
        300: `#c9c9c9`,
        400: `#b2b2b2`,
        500: `#9a9a9a`,
        600: `#838383`,
        700: `#6c6c6c`,
        800: `#555555`,
        900: `#3e3e3e`,
      },
      pink: {
        default: `#ea4765`,
        100: `#fed7de`,
        200: `#fcb0bd`,
        300: `#f78ca0`,
        400: `#f26982`,
        500: `#ea4765`,
        600: `#c73c56`,
        700: `#a43146`,
        800: `#812738`,
        900: `#5e1b27`,
      },
      blue: {
        100: `#EAF3F9`,
        200: `#C5DEF0`,
        300: `#9EC7E9`,
        400: `#75ADE4`,
        500: `#4A90E2`,
        600: `#4B7EC4`,
        700: `#496EA7`,
        800: `#465F8C`,
        900: `#425172`,
        microsoft: `#0078D4`,
      },
      red: {
        100: `#fff5f5`,
        200: `#fed7d7`,
        300: `#feb2b2`,
        400: `#fc8181`,
        500: `#f56565`,
        600: `#e53e3e`,
        700: `#c53030`,
        800: `#9b2c2c`,
        900: `#742a2a`,
      },

      red2: {
        u: `#df4956`,
        200: `#D79B9B`,
        300: `#cd7882`,
        400: `#BE5F69`,
        500: `#BA4756`,
        600: `#963C4B`,
        700: `#732D37`,
        800: `#471f27`,
      },

      black2: {
        700: `#282828`,
      },

      yellow2: {
        200: `#FEF9Eb`,
        300: `#FFF4D2`,
        400: `#FFDFA0`,
        500: `#FFD787`,
      },

      neutral: {
        200: `#CCCCCC`,
        300: `#A3A3A3`,
        400: `#7A7A7A`,
        500: `#5C5C5C`,
        600: `#424242`,
        700: `#282828`,
        800: `#171717`,
      },

      'revamp-red': {
        DEFAULT: '#DF4956',
        100: '#E2B6B6',
        200: '#D79B9B',
        300: '#CD7882',
        400: '#BE5F69',
        500: '#BA4756',
        600: '#963C4B',
        700: '#732D37',
        800: '#471F27',
      },
      'revamp-blue': {
        DEFAULT: '#4896E5',
        100: '#A8C5E1',
        200: '#8CB2D7',
        300: '#6EA0CD',
        400: '#5A96CD',
        500: '#3C7DBE',
        600: '#3C699B',
        700: '#2D5073',
        800: '#23374B',
      },
      'revamp-green': {
        DEFAULT: '#59B261',
        100: '#B2C7A8',
        200: '#95B286',
        300: '#85A674',
        400: '#759963',
        500: '#678C55',
        600: '#446633',
        700: '#2B471D',
        800: '#233B17',
      },
      'revamp-mauve': {
        DEFAULT: '#945CB6',
        100: '#C3AFCF',
        200: '#B49BC3',
        300: '#A587B2',
        400: '#9669A5',
        500: '#875A9B',
        600: '#6E4182',
        700: '#552864',
        800: '#37243D',
      },
      'revamp-turquoise': {
        DEFAULT: '#5CCCC3',
        100: '#A2D2CE',
        200: '#7CBFBA',
        300: '#55AFA5',
        400: '#3BA29B',
        500: '#08998D',
        600: '#2E736D',
        700: '#235954',
        800: '#184441',
      },
      'revamp-neutral': {
        100: '#E6E6E6',
        200: '#CCCCCC',
        300: '#A3A3A3',
        400: '#7A7A7A',
        500: '#5C5C5C',
        600: '#424242',
        700: '#282828',
        800: '#471F27',
      },
      'revamp-pink-salt': '#F8ECE5',
      'revamp-yellow': {
        200: '#FEF9EB',
        300: '#FFF4D2',
        400: '#FFDFA0',
        500: '#FFD787',
      },
      'revamp-gray': {
        DEFAULT: '#E9EFF3',
        50: '#F1F5F8',
        100: '#E6EDF1',
        200: '#B8C4CC',
        300: '#B8C4CC',
        400: '#A4B1B8',
        500: '#8D9BA3',
        600: '#768892',
        700: '#435261',
        800: '#384250',
        900: '#272A33',
      },
      'light-blue': '#E9F0F3',
    },
    screens: {
      xs: `320px`,
      sm: `480px`,
      nav: `620px`,
      md: `768px`,
      lg: `992px`,
      xl: `1200px`,
      print: { raw: `print` },

      xsm2: `500px`,
      sm2: `820px`,
      md2: `1024px`,
      lg2: `1040px`,
      xl2: `1280px`,
      '2xl2': `1325px`,

      xs3: '475px',
      sm3: '640px',
      md3: '744px',
      xmd3: '880px',
      lg3: '1024px',
      xl3: '1280px',
      '2xl3': '1440px',
    },
    fontFamily: {
      sans: [`Futura`, `Helvetica`, `Arial`, `sans-serif`],
      'sans-raw': [`Arial`, `sans-serif`],
      avenir: [`Avenir Next`, `Futura`, `Helvetica`, `Arial`, `sans-serif`],
      libreBaskerville: [`Libre Baskerville`, `serif`],
    },
    fontSize: {
      '3xs': `.6rem`,
      '2xs': `.6666rem`,
      xs: `.75rem`,
      sm: `.875rem`,
      base: `1rem`,
      lg: `1.125rem`,
      xl: `1.25rem`,
      '2xl': `1.5rem`,
      '3xl': `1.875rem`,
      '4xl': `2.25rem`,
      '5xl': `3rem`,
      '6xl': `4rem`,

      '3xl2': `4.5rem`,
      '2xl2': `3.75rem`,
      xl2: `3rem`,
      lg2: `2.5rem`,
      md2: `2rem`,
      normal2: `1.5rem`,
      body2: `1.25rem`,
      sm2: `1rem`,
      xs2: `0.875rem`,
    },
    fontWeight: {
      normal: 400,
      semibold: 500,
      bold: 700,

      400: 400,
      500: 500,
      600: 600,
      700: 700,
    },
    lineHeight: {
      0: 0,
      negative: 0.8675,
      none: 1,
      tight: 1.125,
      snug: 1.3333,
      normal: 1.5,
      relaxed: 1.6666,
      loose: 2,

      small: '1.1',
      mid: '1.2',

      '3xl2': `4.5rem`,
      '2xl2': `3.75rem`,
      xl2: `3rem`,
      lg2: `2.5rem`,
      md2: `2rem`,
      normal2: `1.5rem`,
      body2: `1.25rem`,
      sm2: `1rem`,
      xs2: `0.875rem`,
    },
    /* extend default theme values */
    extend: {
      inset: {
        100: `100%`,
      },
      spacing: {
        '1/2': `50%`,
        '1/3': `33.333333%`,
        '9/16': `56.25%`,
        full: `100%`,
      },
      maxWidth: {
        none: `none`,
        //   xs: `320px`,
        // sm: `480px`,
        // nav: `620px`,
        // md: `768px`,
        // lg: `992px`,
        '1/2': `50%`,
        sm2: `820px`,
        md2: `1000px`,
        lg: `1200px`,
        xl2: `1440px`,
      },
      dropShadow: {
        sm2: `0 4px 20px rgba(0,0,0,0.12)`,
      },
    },
  },
  plugins: [
    // denormalized tailwind custom-forms plugins to work around build problem
    function ({ addComponents }) {
      const options = {
        horizontalPadding: defaultTheme.spacing[2],
        verticalPadding: defaultTheme.spacing[1],
        lineHeight: defaultTheme.lineHeight.normal,
        fontSize: defaultTheme.fontSize.base,
        borderColor: `#b2b2b2`,
        borderWidth: defaultTheme.borderWidth.default,
        borderRadius: defaultTheme.borderRadius.default,
        backgroundColor: colors.white,
        focusBorderColor: colors.blue[400],
        focusShadow: defaultTheme.boxShadow.outline,
        boxShadow: defaultTheme.boxShadow.none,
        checkboxSize: `1em`,
        radioSize: `1em`,
        checkboxIcon: `<svg viewBox="0 0 16 16" fill="#fff" xmlns="http://www.w3.org/2000/svg"><path d="M5.707 7.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4a1 1 0 0 0-1.414-1.414L7 8.586 5.707 7.293z"/></svg>`,
        radioIcon: `<svg viewBox="0 0 16 16" fill="#fff" xmlns="http://www.w3.org/2000/svg"><circle cx="8" cy="8" r="3"/></svg>`,
        checkedColor: `currentColor`,
        selectIcon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="${colors.gray[500]}"><path d="M15.3 9.3a1 1 0 0 1 1.4 1.4l-4 4a1 1 0 0 1-1.4 0l-4-4a1 1 0 0 1 1.4-1.4l3.3 3.29 3.3-3.3z"/></svg>`,
        selectIconOffset: defaultTheme.spacing[2],
        selectIconSize: `1.5em`,
      }

      addComponents({
        '.form-checkbox': {
          appearance: `none`,
          boxShadow: options.boxShadow,
          display: `inline-block`,
          height: options.checkboxSize,
          width: options.checkboxSize,
          verticalAlign: `middle`,
          borderColor: options.borderColor,
          borderWidth: options.borderWidth,
          borderRadius: options.borderRadius,
          backgroundColor: options.backgroundColor,
          backgroundOrigin: `border-box`,
          userSelect: `none`,
          '&:focus': {
            outline: `none`,
            boxShadow: options.focusShadow,
          },
          '&:focus:not(:checked)': {
            borderColor: options.focusBorderColor,
          },
          '&:checked': {
            backgroundColor: options.checkedColor,
            borderColor: options.checkedColor,
            backgroundImage: `url("${svgToDataUri(options.checkboxIcon)}")`,
            backgroundSize: `100% 100%`,
            backgroundPosition: `center`,
            backgroundRepeat: `no-repeat`,
          },
          '&::-ms-check': {
            color: `transparent`, // Hide the check
            background: `inherit`,
            borderColor: `inherit`,
            borderRadius: `inherit`,
            borderWidth: options.borderWidth,
          },
        },
        '.form-radio': {
          appearance: `none`,
          boxShadow: options.boxShadow,
          display: `inline-block`,
          height: options.radioSize,
          width: options.radioSize,
          verticalAlign: `middle`,
          borderColor: options.borderColor,
          borderWidth: options.borderWidth,
          borderRadius: `50%`,
          backgroundColor: options.backgroundColor,
          backgroundOrigin: `border-box`,
          userSelect: `none`,
          '&:focus': {
            outline: `none`,
            boxShadow: options.focusShadow,
          },
          '&:focus:not(:checked)': {
            borderColor: options.focusBorderColor,
          },
          '&:checked': {
            backgroundColor: options.checkedColor,
            borderColor: options.checkedColor,
            backgroundImage: `url("${svgToDataUri(options.radioIcon)}")`,
            backgroundSize: `100% 100%`,
            backgroundPosition: `center`,
            backgroundRepeat: `no-repeat`,
          },
          '&::-ms-check': {
            color: `transparent`, // Hide the dot
            background: `inherit`,
            borderColor: `inherit`,
            borderRadius: `inherit`,
            borderWidth: options.borderWidth,
          },
        },
        '.form-input, .form-textarea, .form-multiselect': {
          appearance: `none`,
          backgroundColor: options.backgroundColor,
          borderColor: options.borderColor,
          borderWidth: options.borderWidth,
          borderRadius: options.borderRadius,
          boxShadow: options.boxShadow,
          padding: `${options.verticalPadding} ${options.horizontalPadding}`,
          fontSize: options.fontSize,
          lineHeight: options.lineHeight,
          '&:focus': {
            outline: `none`,
            boxShadow: options.focusShadow,
            borderColor: options.focusBorderColor,
          },
        },
        '.form-select': {
          backgroundColor: options.backgroundColor,
          borderColor: options.borderColor,
          borderWidth: options.borderWidth,
          borderRadius: options.borderRadius,
          boxShadow: options.boxShadow,
          paddingTop: options.verticalPadding,
          paddingRight: `calc(${options.selectIconOffset} * 2 + ${options.selectIconSize})`,
          paddingBottom: options.verticalPadding,
          paddingLeft: options.horizontalPadding,
          fontSize: options.fontSize,
          lineHeight: options.lineHeight,
          appearance: `none`,
          backgroundImage: `url("${svgToDataUri(options.selectIcon)}")`,
          backgroundPosition: `right ${options.selectIconOffset} center`,
          backgroundRepeat: `no-repeat`,
          backgroundSize: `${options.selectIconSize} ${options.selectIconSize}`,
          '&::-ms-expand': {
            display: `none`,
          },
          '&:focus': {
            outline: `none`,
            boxShadow: options.focusShadow,
            borderColor: options.focusBorderColor,
          },
        },
      })
    },
  ],
}
