export default {
  title: 'Restez en contact avec nous',
  description: `Recevez des nouvelles et des mises à jour sur les cours et les événements d’AFI.`,
  cta: `Abonnez-vous à nos communications et découvrez`,
  summary: {
    one: `Nos programmes de formations et nos sessions garanties`,
    two: `Les tendances, innovations et bonnes pratiques`,
    three: `Des articles de blogue`,
    four: `Des exclusivités et les conseils de nos experts`,
  },
  subscribebutton: `S'inscrire à l'infolettre`,
}
