export default {
  aboutUsTitle: 'Who are we?',
  afiByEdgenda: 'AFI by Edgenda',
  afiByEdgendaCoachingUrl: 'https://www.afiparedgenda.com/en',
  afiByEdgendaFastNumericLearningsUrl: 'https://www.afiparedgenda.com/en',
  afiByEdgendaLearningExperiencesUrl: 'https://www.afiparedgenda.com/en',
  afiByEdgendaPersonalizedProgramsUrl: 'https://www.afiparedgenda.com/en/programs',
  afiByEdgendaRootUrl: 'https://www.afiparedgenda.com/en',
  afiByEdgendaUrl: 'https://www.afiparedgenda.com/en',
  afiU: 'Afi\xa0U.',
  apprentxB12Url: 'https://www.apprentx.rocks/b12',
  apprentxRootUrl: 'https://www.apprentx.rocks',
  backToCatalog: 'Back to the catalog',
  contact: 'Contact',
  contactUrl: 'https://lp.afiexpertise.com/contact-us-afiu',
  cookieSettings: 'Personalize cookies',
  doYouHaveQuestions: 'Do you have any questions? We have answers.',
  detailsAndStartCta: 'Details and start dates of the upcoming cohorts.',
  edgendaCoachingUrl: 'https://edgenda.com/en/human',
  edgendaCopyright: 'Copyright © 2024 Edgenda inc.',
  edgendaLearningUrl: 'https://edgenda.com/en/training',
  edgendaRootUrl: 'https://www.edgenda.com/en',
  edgendaStrategyUrl: 'https://edgenda.com/en/strategy-operations',
  edgendaTechnologyUrl: 'https://edgenda.com/en/technology',
  keepPosted: 'I always want to learn',
  new: 'New',
  magazineUrl: 'https://lp.afiexpertise.com/en/afi-u-training-catalog',
  mainTitle: 'Professional training and coaching',
  newsletterLink: '/en/newsletter',
  newsletterSubscribe: 'Stay in touch',
  rentOfficeUrl: 'https://edgenda.formstack.com/forms/location_de_salle_copy',
  requestQuote: 'Request a quote',
  takeMeetingUrl: 'https://meetings.hubspot.com/marie-claude-langlois/schedule-a-meeting',
  yourMail: 'Your email',
  readMore: 'More',
  readLess: 'Less',
  homeMainTitle: 'Never stop Learning',
  prioritiesText: 'Explore learning experiences that resonate with your business priorities.',
  homeColoredTitle: {
    firstPart: 'The School of Business and Technology',
    secondPart: 'for future-ready skills',
  },
  or: 'or',
  viewFullList: 'view full list',
  homeParagraphs: {
    first:
      "In today's digital landscape, where embracing learning, unlearning, and relearning is key to success, we engage with organizations to nurture their most valuable asset: YOU.",
    second:
      'Now, more than ever, the continuous cycle of upskilling and reskilling must evolve in real-time, fueled by diverse learning experiences and vibrant human connections.',
    third:
      'More than just a school, Afi\xa0U. is a dynamic community sharing enthusiasm for learning, knowledge, and hands-on experiences. We empower you with the confidence to take decisive action now.',
  },
  forIndividuals: 'For individuals',
  forIndividualsText: 'Unlock your potential through relevant learning opportunities.',
  businessPriorities: 'Business Priorities',
  programsMicroprograms: 'Programs and Microprograms',
  offShelfTrainings: 'Off-The-Shelf Training Courses',
  afiUCircles: 'Circles by Afi\xa0U.',
  forOrganizations: 'For organizations',
  forOrganizationsText: 'Develop agile teams to drive impactful business transformation.',
  privateDelivery: 'Private Delivery',
  learningWorkshops: 'Collaborative Learning Workshops',
  businessCoaching: 'Business Coaching',
  customPrograms: 'Custom Programs',
  liveAfiUExperience: {
    beforeLogo: 'Dive into the',
    afterLogo: 'Experience',
  },
  liveAfiUText: {
    boldPart: 'Find',
    regularPart: 'your program, microprogram, or off-the shelf training',
  },
  searchText: 'Enter your keywords',
  newsForYou: 'Explore what sets us apart',
  neverStopLearning: {
    boldPart: 'Never',
    regularPart: 'stop learning',
  },
  exploreActTransform: 'Ready to welcome AI<br/>into your organization?',
  downloadExplore: 'Download our brochure',
  exploreText:
    'Learning experiences that prepare your teams to excel in a continuously evolving future.',
  download: 'Download',
  organizationsTitle: 'Organizations driving a positive change through the power of skills',
  organizationsText:
    'Forward-thinking organizations, empowering their teams to contribute to their success while shaping a better world.',
  certifiedTrainings: 'Certified Trainings Courses',
  aboutUs: 'About Us',
  afiUExperience: 'Afi\xa0U. Experience',
  theTeam: 'The Team',
  ourOffices: 'Our Offices',
  partnersAndCertifications: 'Partners and Certifications',
  careers: 'Careers',
  faq: 'FAQ',
  visitWebsite: 'Visit Website',
  designDevelopment: 'Learning Experiences Design and Development',
  rapidELearning: 'Rapid eLearning Solutions',
  apprentxByEdgenda: 'Apprentx by Edgenda',
  edgenda: 'Edgenda',
  strategyOperations: 'Strategy and Operations',
  coachingLeadership: 'Coaching and Leadership',
  emergingTechnologies: 'Emerging Technologies',
  learning: 'Learning',
  edgendaFamily: 'The Edgenda Family',
  needAssistance: 'Need assistance ?',
  talkSales: 'Talk to one of our sales consultant',
  talkToAdvisor: 'Talk to an advisor',
  orCall: 'or call',
  privacyPolicy: 'Privacy Policy',
  privacyText:
    'Authorized Training Center Emploi-Québec, Agrément : 0051460 | TPS : 141 582 528 - TVQ : 1019557738',
  newsletterText: 'Stay updated on trends, innovations, best practices and exclusive insights.',
  comingSoon: 'Coming soon',
  seeTheOthers: 'See the others',
  readyCta: 'Ready ? Start here',
  afiUSubtitle: {
    program: 'Afi\xa0U. program',
    microprogram: 'Afi\xa0U. microprogram',
    masterClass: 'Afi\xa0U. masterclass',
  },
  afiUSubtitleSEOTitle: {
    program: 'Program',
    microprogram: 'Microprogram',
    masterClass: 'Masterclass',
  },
  nextCohort: 'Start date',
  duration: 'Duration',
  fee: 'Fee',
  format: 'Format',
  startHereCta: 'Start here',
  elevateYourSkills: 'Ready to elevate your skills?',
  beReadyTo: {
    firstPart: 'Be ready to ...',
    secondPart: '... and drive immediate value for your organization',
  },
  experienceColoredTitle: {
    firstPart: 'Experience the learning journey',
    microprogramSecondPart: 'with Afi\xa0U.',
    programSecondPart: 'with Afi\xa0U.',
    masterClassSecondPart: 'with Afi\xa0U.',
  },
  engageText: {
    program: 'Engage with your program',
    microprogram: 'Engage with your microprogram',
    masterClass: 'Engage with your masterclass',
  },
  caseStudies: 'Case studies',
  interestedInThis: {
    program: 'Interested in this program?',
    microprogram: 'Interested in this microprogram?',
    masterClass: 'Interested in this masterclass?',
  },
  takeTheNextStep: 'Take the next step',
  onlineRegistration: 'Online registration',
  needPersonalizedConsultation: 'Need a personalized consultation?',
  scheduleMeeting: 'Schedule a meeting with an advisor',
  questions: 'Questions?',
  invitedContributorsAndExperts: 'Invited contributors and experts',
  studyPlan: 'Study plan',
  downloadBrochure: 'Download brochure',
  blog: 'Blog',
  fundingPrograms: `Funding programs`,
  liveAfiUFamilleParagraph:
    'The Edgenda Family, unites experts working together to drive business transformation. We harness human talent, powered by technology, to accelerate organizational success.',
  liveAfiUPropellSuccess: "Propelling Your Success.\nHuman AND Technology,\nit's our edge.",
  themesAmbassadorsText:
    'Our ambassadors lead and curate course selections to enpower your projects and your ambitions.',
  the: 'The',
  the_plural: 'The',
  and: 'and',
  from: 'from',
  to: 'to',
  microprogram: 'Microprogram',
  microprograms: 'Microprograms',
  program: 'Program',
  programs: 'Programs',
  detailsAndRegistration: 'Details and registration',
  stayTuned: 'Stay tuned, big announcement coming soon!',
  offTheShelf: 'Off-the-shelf',
  mostPopularCourses: 'Most popular Off-The-Shelf Training Courses',
  circlesAfiU: 'Circles by Afi\xa0U.',
  circleAfiU: 'Circle by Afi\xa0U.',
  profile: 'Profile',
  nextMeeting: 'Next meeting',
  conferences: 'Conferences',
  businessCoach: 'Business coach',
  threeNinetyMinutesSessions: '3 x 90 minutes sessions',
  teamParagraph:
    "Afi\xa0U.'s success is deeply intertwined with the quality of its people. We place great importance on respecting both your organizational culture and the unique personalities of individuals. Our attentiveness and understanding of your challenges enable us to find within our team THE person to meet YOUR needs.",
  teamTitle: 'Prioritizing people, always.',
  trainersCertifiedCoaches: 'Trainers - Certified Coaches',
  leadership: 'Leadership',
  customerExperience: 'Customer Experience',
  trainers: 'Trainers',
  organizationsPage: {
    firstColoredTitle: {
      subTitle: 'A results-driven practical approach',
      firstPart: 'Empowering organizations',
      secondPart: 'through learning solutions',
    },
    firstParagraph:
      'Afi\xa0U. collaborates with organizations to build skills development plans and deliver learning solutions that support transformational projects. Our team combines extensive experience in business consulting and the creation of learning experiences to better address your opportunities and challenges.',
    secondParagraph: {
      firstPart:
        'Bringing an Afi\xa0U. program, microprogram, or training to one or more groups within your organization is possible.',
      secondPart:
        'Offered in various formats virtually, in-person - at your location or in our learning spaces - or in a hybrid fashion, our learning experiences prepare your teams to navigate the evolving current business landscape, handle ambiguity with ease, and respond boldly and innovatively.',
    },
    secondColoredTitle: {
      firstPart: 'Collaborative',
      secondPart: 'Learning Workshops',
    },
    thirdParagraph: {
      firstPart:
        'Align your teams with common objectives by leveraging the effective formula of the Circles by Afi\xa0U., combining the power of collective intelligence and action-based learning.',
      secondPart:
        'Guided and facilitated by a certified business coach, our collaborative learning workshops offer a confidential, open, and judgment-free environment for learning and experimentation.',
      thirdPart:
        'Dare to spark conversations to establish a customized action plan for your organization.',
    },
    quote: {
      text:
        "“\xa0It's always so powerful to facilitate moments that click and see teams mobilize and transform through collaborative learning workshops\xa0”",
      authorFullName: 'Marie-Hélène Demers',
      authorTitle: 'Vice-President, Coaching and Leadership',
    },
    firstList: [
      'Collaboration and teamwork',
      'Psychological well-being',
      'Cohesion and team spirit reinforcement',
      'Diversity, equity, and inclusion in the workplace',
      'Alignment and strategic vision',
      'Empowerment and autonomy',
      'Effective and authentic communication',
    ],
    fourthParagraph:
      'The AFI by Edgenda team guides individuals, teams, and organizations towards reaching their highest peaks. An effective solution in a context of continuous change.',
    secondList: [
      'Organizational Transformation',
      'Executive and Leadership Coaching',
      'Culture and Team',
      'Performance and Talent',
      'Coaching for IT Professionnals',
      'Diversity, Equity and Inclusion',
      'Coach Supervision',
    ],
    ctaCoaching: 'Explore our Business coaching',
    ctaUrl: 'https://afiparedgenda.com/en/coaching',
    thirdColoredTitle: {
      subTitle: 'Accelerate the development of your teams',
      firstPart: 'Custom',
      secondPart: 'Programs',
    },
    fifthParagraph: {
      firstPart:
        "The AFI by Edgenda learning experience design team can collaborate with your organization to contextualize and enhance Afi\xa0U.'s learning content.",
      secondPart: {
        boldPart: 'The result?',
        regularPart: 'A proven solution to address specific development needs.',
      },
    },
    programCta: 'Explore customization options',
  },
  contactSales: 'Contact a sales advisor',
  officePage: {
    coloredTitle: {
      firstPart: 'Let your learning',
      secondPart: 'inhabit you',
    },
    firstParagraph:
      'Physical, technological, and human spaces that foster attention, engagement, and human connection.',
    rentCta: 'Rent these spaces',
    secondParagraph: {
      firstPart:
        'Inspired by nature, the spaces are bathed in light and greenery. Both have a proven impact in workplace environments, increasing productivity by 6%, creativity by 15%, and overall well-being by 15%.',
      secondPart:
        "We integrate powerful {{underlineWord1}} technology, based on the Microsoft {{underlineWord2}} platform, which captures sound and image to deliver a natural and hybrid experience. Movements and interactions are continuously tracked through artificial intelligence, ensuring you don't miss a thing.",
    },
    helpText:
      'Each section can be configured to accommodate custom experiences based on your needs and creative ideas.',
    photoCredit: 'Photograph : Paul Dussault',
    seeThe360Images: 'View 360 images',
    rentSection: {
      title: 'Available for Rent',
      text:
        'Whether you need a workspace, training room, business lounge, or creative space, our new facilities are designed to stimulate innovation and collaboration.',
      list: ['Comfortable Rest Area', 'Free Wi-Fi', 'Unlimited Coffee'],
    },
    sections: [
      {
        title: 'Training Rooms with Computers',
        text:
          'Use our computer-equipped rooms to train your team and only pay for the additional services you truly need.',
      },
      {
        title: 'Conference Rooms',
        text: 'Our setups are perfect for hosting conferences and small events.',
      },
      {
        title: 'Meeting Rooms',
        text:
          'Utilize our presentation equipment (screen, projector, whiteboard, etc.) to facilitate your meetings.',
      },
      {
        title: 'Individual Workspaces (pods)',
        text: 'Our individual spaces allow you to work in peace. Ideal for mobile workers.',
      },
      {
        title: 'Event Spaces',
        text:
          'Our facilities allow you to host a reception, cocktail party, or networking event in a friendly and warm atmosphere.',
      },
      {
        title: 'Lounges',
        text:
          "Whether you're organizing an off-site meeting, a creative workshop, or a seminar, let your imagination run wild. Our spaces allow you to arrange furniture and equipment according to your needs.",
      },
    ],
    turnkeyServiceSection: {
      title: 'Turnkey Service',
      listText: 'We can take care of coordinating your events, such as:',
      list: [
        'Trainings',
        'Corporate presentations',
        'Business meetings',
        'Symposiums, conferences, and seminars',
        'Laboratories',
        'Technological simulations',
        '… and much more.',
      ],
      receptionText: 'We also offer a reception service.',
    },
    openHours: {
      title: 'Opening Hours: Monday to Friday from 8 am to 5 pm.',
      text: 'Available upon request: From 5 pm to 11 pm or on weekends.',
    },
    contactUs: {
      title: 'Contact Us',
      text: 'Our sales advisors are available to guide you as needed and answer your questions.',
    },
    informationRequest: 'Information request',
  },
  programsPage: {
    coloredTitle: {
      firstPart: 'More options',
      secondPart: 'to enhance your impact.',
    },
    firstParagraph:
      'Whether you are looking to develop or deepen skills, or to quickly acquire specialized expertise,',
    programsText:
      'Increase your impact by experimenting with the foundational aspects of major organizational trends in your own practice.',
    microprogramsText:
      'Rapidly develop expertise in a specific field with an intensive learning experience that equips you with immediately applicable knowledge in your work environment.',
    secondTitle: 'Why choose an Afi\xa0U. program or microprogram?',
    gridContent: [
      {
        title: 'Relevance of content',
        text:
          'Our programs and microprograms are developed in alignment with the key priorities of organizations, aiming to fill the knowledge and skill gaps recognized by the market.',
      },
      {
        title: 'Technology and Delivery Platform',
        text:
          'In addition to leveraging the latest trends in educational technologies, our programs and microprograms are powered by our B12 application. This application uses technology and science to accelerate and enhance the impact of your learning.',
      },
      {
        title: 'Expertise of the trainers',
        text:
          'Renowned experts and practitioners in their respective fields, our trainers are committed to advancing conversations and stimulating learning.',
      },
      {
        title: 'Human-centered customer experience',
        text:
          'Afi\xa0U. offers a human and seamless experience supported by a dedicated team. Therefore, it is possible to speak to someone at each stage of your journey with us.',
      },
    ],
    helpText: 'Need information or advice?',
  },
  circlesPage: {
    subTitle: 'Together we go further.',
    firstParagraph: 'Combine the power of collective intelligence and learning through action.',
    secondParagraph: {
      firstPart:
        'Dive into a welcoming virtual space where you are invited to share your challenges and professional goals to progress collectively.',
      secondPart:
        'Guided by a business coach, each Afi\xa0U. Circle offers a specific context and a series of three group sessions to propel the development of your skills and accelerate the transformation of your challenges into opportunities.',
    },
    exploreText: 'Explore our upcoming Circles by Afi\xa0U.',
    whyParticipate: 'Why participate in Circles by Afi\xa0U.?',
    gridContent: [
      {
        title: 'Definition of goals and Implementation',
        text:
          'With the structure of its sessions, Afi\xa0U. Circles allow you to clarify your goals and identify your next actions.',
      },
      {
        title: 'Diversity of Perspectives',
        text:
          'Access diverse perspectives and problem-solving approaches that you might not have considered on your own.',
      },
      {
        title: 'Empowerment and Autonomy',
        text:
          'Take charge of your professional development and harness the power of the collective to stay engaged and support your momentum.',
      },
      {
        title: 'Networking Opportunities',
        text:
          'Take advantage of structured exchanges to build connections with other participants and explore possible professional networks.',
      },
      {
        title: 'Return on Investment',
        text:
          'Explore a cost-effective alternative to individual coaching. The approach requires lower financial and time investments while creating a tangible impact on your development.',
      },
    ],
    helpText: 'Need guidance in choosing your circle?',
  },
  nextCircle: 'Next circle',
  register: 'Register',
  pricing: 'Pricing',
  virtual: 'Virtual',
  sevenFiftyDollars: '750 $',
  yourBusinessCoach: 'Your business coach',
  allPrograms: 'All the Programs',
  allMicroprograms: 'All the Microprograms',
  contactCta: 'Contact us',
  quebec: 'Quebec',
  quebecAddress: '1751, Marais Street, #300 G1M 0A2',
  quebecAddressDescription: 'Ample Parking with Easy Access via Major Roadways.',
  montreal: 'Montreal',
  montrealAddress: '500, Saint-Jacques Street, #500 H2Y 1S1',
  montrealAddressDescription:
    'In the heart of downtown Montreal, near Square-Victoria-OACI subway station.',
  tollFree: 'Toll free',
  masterClass: 'MasterClass',
  visit: 'Visit',
  specialOffer: {
    yourAdvisor: 'Your advisor',
    requestInformation: 'Request information',
    learnMore: 'Learn more',
    for: 'for',
    welcomeToThePortal: {
      firstPart: 'Welcome to the',
      secondPart: 'portal',
    },
  },
  learningExperienceSEOTitle: 'Learning Experiences',
  featuredProgram: 'Featured training course',
}
