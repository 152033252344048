export default {
  confirmCart: `Confirm cart`,
  entitledToPrefRate: `I’m entitled to the preferential price`,
  promoCode: {
    title: `I have a promo Code`,
    note: `Promotional code applies to regular price, excluding material.`,
    validate: `Validate`,
    invalid: `This code is invalid`,
  },
  promoCodeError: {
    IN_BLACKLIST: `This code does not apply to this course`,
    INVALID_CODE: `This code is invalid`,
  },
  register: `Proceed to registration`,
  yourSelection: `Your training selection`,
}
