export default {
  AfiDivisionEdgenda: `The AFI experience brought to you by Edgenda`,
  AfiDivisionEdgendaPresentation: `At AFI, our focus is to offer you relevant training and impactful learning experiences in line with your transformation approach. Do you need support for your approach? Check out Edgenda’s services:`,
  and: `and`,
  authorizedCenter: `Training center accredited by Emploi-Québec, Accreditation`,
  backToPublications: `Back to publications`,
  backToTrainers: `Back to trainers`,
  by: `By`,
  catalog: {
    cta: `Open more doors.<br/>Download our 2023 Explore magazine`,
  },
  close: `Close`,
  contactUs: `Contact us`,
  continueReading: `Continue reading`,
  email: `Email`,
  home: `Home`,
  language: {
    en: `English`,
    fr: `French`,
  },
  learnMore: `Learn more`,
  montreal: `Montreal`,
  newsletter: {
    cta: `Be aware of trends, innovations and best practices, every month.`,
    placeholder: `Your email here`,
  },
  or: `or`,
  seeFullCatalog: `See the full catalog`,
  pageNotFound: {
    metaTitle: 'Not found',
    title: `Oops. This page does not exist.`,
    suggestion: `Try the training keyword search.`,
    contact: `If you still cannot find what you are looking for, do not hesitate to contact us.`,
  },
  pageEmailSignature: {
    headerTitle: `Your own e-mail signature`,
    instructions: `Please complete the fields that concern you:`,
    brand: `Brand:`,
    name: `Name:`,
    email: `E-mail:`,
    title: `Title:`,
    phone: `Phone (office):`,
    phoneExtension: `Ext.:`,
    tollFree: `Phone, Toll free:`,
    cell: `Mobile phone:`,
    linkedIn: `Linked-in profile:`,
    phoneLabel: `Office:`,
    extLabel: `#`,
    tollFreeLabel: `Toll free:`,
    cellLabel: `Mobile:`,
    downloadCatalog: {
      afi: `Downlaod our Catalog`,
      edgenda: `Find your training in our Catalog`,
    },
    urlCatalog: {
      afi: `https://www.afiexpertise.com/AFI-Explore-2023-EN.pdf`,
      edgenda: `https://www.afiexpertise.com/AFI-Explore-2023-EN.pdf`,
    },
    website: {
      afi: `https://www.afiexpertise.com/en/`,
      edgenda: `https://edgenda.com/en/`,
    },
    copyHtml: `Copy HTML`,
    downloadHtml: `Download HTML`,
    copied: `Copied!`,
    videoInstructions: `Integration instructions in Outlook 365 (in french):`,
  },
  quebec: `Quebec`,
  search: `Training keywords search`,
  tollFree: `Toll free`,
  tps: `GST`,
  tvq: `QST`,
  trainingOfferedBy: `Training offered by`,
  trainingOfferedBy_plural: `Trainings offered by`,
  viewPhotosGallery: `View photos gallery`,
}
