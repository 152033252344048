import React, { ReactNode, useEffect, useState } from 'react'

export type Props = {
  children: ReactNode
}
export type ToggleDrawerContextType = {
  opened: boolean
  setOpened: (open: boolean) => void
}

const MenuDrawerContext = React.createContext<ToggleDrawerContextType>({
  opened: false,
  setOpened: function(open: boolean) {
    this.opened = open
  },
})

function MenuDrawerContextProvider({ children }: Props) {
  const [opened, setOpened] = useState(false)
  const value = { opened, setOpened }

  useEffect(() => {
    document.body.dataset.isMenuDrawerOpened = opened ? `opened` : `closed`
  }, [opened])

  return <MenuDrawerContext.Provider value={value}>{children}</MenuDrawerContext.Provider>
}

export { MenuDrawerContext, MenuDrawerContextProvider }
