import isNil from 'lodash/isNil'
import unset from 'lodash/unset'
import React, { ReactNode, useEffect, useState } from 'react'

export type Props = {
  children: ReactNode
}
export type StringOrNullType = string | null
export type ToggleDownloadingContextType = {
  downloading: StringOrNullType
  setDownloading: (downloading: StringOrNullType) => void
}

const DownloadingContext = React.createContext<ToggleDownloadingContextType>({
  downloading: null,
  setDownloading: function (downloading: StringOrNullType) {
    this.downloading = downloading
  },
})

function DownloadingContextProvider({ children }: Props) {
  const [downloading, setDownloading] = useState<StringOrNullType>(null)
  const value = { downloading, setDownloading }

  useEffect(() => {
    if (isNil(downloading)) {
      unset(document.body.dataset, `isDownloading`)
    } else {
      document.body.dataset.isDownloading = downloading
    }
  }, [downloading])

  return <DownloadingContext.Provider value={value}>{children}</DownloadingContext.Provider>
}

export { DownloadingContext, DownloadingContextProvider }
