import React, { ReactNode } from 'react'

export type I18nProps = {
  children: ReactNode
  pageKey: string
  lang: string
  paths: object
}

const defaultI18nContext = {
  pageKey: ``,
  lang: ``,
  paths: {},
}

const I18nContext = React.createContext(defaultI18nContext)

export function I18nProvider({ children, ...rest }: I18nProps) {
  return <I18nContext.Provider value={{ ...rest }}>{children}</I18nContext.Provider>
}

export const I18nConsumer = I18nContext.Consumer

export default I18nContext
