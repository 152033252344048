export default {
  confirmCart: `Confirmation du panier`,
  entitledToPrefRate: `J’ai droit au prix préférentiel`,
  promoCode: {
    title: `J’ai un code promotionnel`,
    note: `Le code promotionnel s’applique au prix régulier, excluant le matériel.`,
    validate: `Valider`,
  },
  promoCodeError: {
    IN_BLACKLIST: `Ce code ne s’applique pas à ce cours`,
    INVALID_CODE: `Ce code est invalide`,
  },
  register: `Procéder à l’inscription`,
  yourSelection: `Votre sélection de formation`,
}
