import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import en from '../locales/en'
import fr from '../locales/fr'

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources: {
      en,
      fr,
    },
    whitelist: [`fr`, `en`],
    defaultNS: `common`,
    fallbackLng: `fr`,
    debug: false,
    detection: {
      order: [`path`, `querystring`, `cookie`, `localStorage`, `navigator`, `htmlTag`, `subdomain`],
      // more options: https://github.com/i18next/i18next-browser-languageDetector
    },

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default

      format: function (value, format, lng) {
        if (format === `localizedNumber`) {
          return new Intl.NumberFormat(lng).format(value)
        }
        return value
      },
    },
  })

export default i18n
