import cart from './cart'
import catalog from './catalog'
import common from './common'
import course from './course'
import form from './form'
import home from './home'
import leadership from './leadership'
import nav from './nav'
import newsletter from './newsletter'
import orphans from './orphans'
import promos from './promos'
import revamp from './revamp'
import search from './search'

export default {
  cart,
  catalog,
  common,
  course,
  form,
  home,
  leadership,
  nav,
  newsletter,
  orphans,
  promos,
  search,
  revamp,
}
