module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/opt/build/repo/src/components/layout/Layout.tsx"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-W7KH6D9","includeInDevelopment":false,"routeChangeEventName":"pv","defaultDataLayer":null,"enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-breakpoints/gatsby-browser.js'),
      options: {"plugins":[],"queries":{"xsmMin":"(min-width: 420px)","xs3":"(min-width: 475px)","sm2Max":"(max-width: 820px)","sm2":"(min-width: 820px)","md":"(min-width: 744px)","md2":"(min-width: 1080px)","md3":"(min-width: 880px)","lg":"(min-width: 1024px)"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Afi U.","short_name":"AFI","lang":"fr-CA","description":"Prêts à élever vos compétences? Afi U. est l'école des organisations reconnue pour son offre en formations professionnelles, affaires et technologies.","start_url":"/","background_color":"#2e3246","theme_color":"#3498DB","display":"minimal-ui","icon":"src/assets/images/afi-icon.png","include_favicon":false,"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":"c659471a6a971ff1b5bad6511465c2c4"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
