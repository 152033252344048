import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid'
import { Link } from 'gatsby'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { FooterColumnData } from './Footer'

type Props = {
  column: FooterColumnData
}

export default function FooterMobileColumn({ column }: Props) {
  const { t } = useTranslation('revamp')
  const [open, setOpen] = useState(false)

  return (
    <div
      role='button'
      className='w-full'
      key={column.title}
      onClick={() => setOpen((prev) => !prev)}
    >
      <div className='pt-4 w-full flex flex-row items-center'>
        <h3 className='font-bold'>{column.title}</h3>
        <div className='flex items-center ml-auto w-6 h-3'>
          {open ? <ChevronUpIcon /> : <ChevronDownIcon />}
        </div>
      </div>

      {open && (
        <div className='flex flex-col pt-4 ml-6 mb-5 gap-y-4'>
          {column.rootUrl && (
            <Link
              to={column.rootUrl}
              className='font-bold text-revamp-blue text-sm hover:underline'
            >
              {t('visitWebsite')}
            </Link>
          )}
          {column.items.map((item) =>
            column.rootUrl ? (
              <a
                className='text-black text-sm hover:underline hover:text-black'
                key={item.title}
                href={item.href}
              >
                {item.title}
              </a>
            ) : (
              <Link
                className='text-black text-sm hover:underline hover:text-black'
                key={item.title}
                to={item.href}
              >
                {item.title}
              </Link>
            )
          )}
        </div>
      )}
    </div>
  )
}
