import { css } from '@emotion/react'
import cx from 'classix'
import { Link } from 'gatsby'
import get from 'lodash/get'
import isFunction from 'lodash/isFunction'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { I18nConsumer } from '@components/context/I18nContext'

import { theme } from '@src/../tailwind.config'

const colors = theme.colors

type Props = {
  isDark: boolean
  closingHandler?: () => void
  linkClassName?: string
}

const LocaleSwitcher = ({ isDark, closingHandler, linkClassName }: Props) => {
  const { i18n } = useTranslation()

  const linkCss = css({
    color: isDark ? colors.white : colors.brandColor,
  })

  return (
    <I18nConsumer>
      {(context) => {
        const lang = get(context, `lang`, i18n.language)
        const otherLang = lang === `en` ? `fr` : `en`
        const to = get(context, [`paths`, otherLang]) || `/${otherLang}`
        return (
          <Link
            className={cx(`font-bold cursor-pointer`, linkClassName)}
            css={linkCss}
            onClick={() => {
              isFunction(closingHandler) && closingHandler()
              i18n.changeLanguage(otherLang)
            }}
            to={to}
          >
            {otherLang.toUpperCase()}
          </Link>
        )
      }}
    </I18nConsumer>
  )
}

export default LocaleSwitcher
