export default {
  AfiDivisionEdgenda: `L’expérience AFI par Edgenda`,
  AfiDivisionEdgendaPresentation: `Chez AFI, nous concentrons nos efforts pour vous offrir des formations pertinentes et des expériences d’apprentissage percutantes qui s’inscrivent dans vos démarches de transformation. Vous désirez être accompagné dans ces démarches? Consultez les services d’Edgenda:`,
  and: `et`,
  authorizedCenter: `Centre de formation autorisé Emploi-Québec, Agrément`,
  backToPublications: `Retour aux publications`,
  backToTrainers: `Retour aux formateurs`,
  by: `Par`,
  catalog: {
    cta: `Ouvrez plus de portes.<br/>Téléchargez le magazine Explore 2023`,
  },
  close: `Fermer`,
  contactUs: `Contactez-nous`,
  continueReading: `Continuez la lecture`,
  email: `Courriel`,
  home: `Accueil`,
  language: {
    en: `Anglais`,
    fr: `Français`,
  },
  learnMore: `Plus de détails`,
  montreal: `Montréal`,
  newsletter: {
    cta: `Soyez au fait des tendances, innovations et bonnes pratiques, chaque mois.`,
    placeholder: `Votre courriel ici`,
  },
  or: `ou`,
  seeFullCatalog: `Voir le catalogue complet`,
  pageNotFound: {
    metaTitle: 'Non trouvé',
    title: `Oups. Cette page n’existe pas.`,
    suggestion: `Essayez la recherche de formation par mots‑clés.`,
    contact: `Si vous ne trouvez toujours pas ce que vous cherchez, n’hésitez pas à nous contacter.`,
  },
  pageEmailSignature: {
    headerTitle: `Votre signature courriel`,
    instructions: `Veuillez remplir les champs qui vous concernent :`,
    brand: `Marque :`,
    name: `Nom :`,
    email: `Adresse courriel :`,
    title: `Fonction :`,
    phone: `Téléphone (bureau) :`,
    phoneExtension: `Poste :`,
    tollFree: `Téléphone, sans frais :`,
    cell: `Téléphone portable :`,
    linkedIn: `Profil Linked-in :`,
    phoneLabel: `Bureau :`,
    extLabel: `#`,
    tollFreeLabel: `Sans frais :`,
    cellLabel: `Cellulaire :`,
    downloadCatalog: {
      afi: `Téléchargez notre catalogue`,
      edgenda: `Consultez notre catalogue de formations`,
    },
    urlCatalog: {
      afi: `https://www.afiexpertise.com/AFI-Explore-2023.pdf`,
      edgenda: `https://www.afiexpertise.com/AFI-Explore-2023.pdf`,
    },
    website: {
      afi: `https://www.afiexpertise.com/fr/`,
      edgenda: `https://edgenda.com`,
    },
    copyHtml: `Copier le HTML`,
    downloadHtml: `Télécharger le HTML`,
    copied: `Copié!`,
    videoInstructions: `Instructions d’intégration dans Outlook 365 :`,
  },
  quebec: `Québec`,
  search: `Recherche de formation par mots‑clés`,
  tollFree: `Sans frais`,
  tps: `TPS`,
  tvq: `TVQ`,
  trainingOfferedBy: `Formation offerte par`,
  trainingOfferedBy_plural: `Formations offertes par`,
  viewPhotosGallery: `Voir la galerie de photos`,
}
