import React from 'react'

// import * as Sentry from '@sentry/browser'

type State = {
  readonly error: any
  readonly eventId: string
}
const initialState: State = { error: null, eventId: `` }

/* eslint-disable @typescript-eslint/explicit-member-accessibility */
class ErrorBoundary extends React.Component<Record<any, any>, State> {
  constructor(props: Record<any, any> | Readonly<Record<any, any>>) {
    super(props)
    this.state = initialState
  }

  componentDidCatch(error: Error): void {
    this.setState({ error })
    // Sentry.withScope(scope => {
    //   scope.setExtras(errorInfo)
    //   const eventId = Sentry.captureException(error)
    //   this.setState({ eventId })
    // })
  }

  render(): React.ReactNode {
    if (this.state.error) {
      //render fallback UI
      return (
        <div className='min-h-screen p-8 flex flex-col items-center justify-center'>
          <p>Une erreur est survenue : essayez de recharger la page.</p>
          <p className='mt-8'>An error occurred: try reloading the page.</p>
          {/* <p>
            Une erreur est survenue : essayez de recharger la page ou{` `}
            <a onClick={() => Sentry.showReportDialog({ eventId: this.state.eventId })}>
              signalez-nous l’erreur
            </a>
          </p>
          <p className='mt-8'>
            An error occurred: try reloading the page or{` `}
            <a onClick={() => Sentry.showReportDialog({ eventId: this.state.eventId })}>
              report the error
            </a>
          </p> */}
        </div>
      )
    } else {
      //when there's not an error, render children untouched
      return this.props.children
    }
  }
}

export default ErrorBoundary
