export const SESSIONS = {
  CATALOG_BACK_SLUG_KEY: `catalogBackSlug`,
  CATALOG_STATE_KEY: `catalogSearchState`,
  CATALOG_FILTERS_KEY: `catalogFilters`,
  HOME_HEADER_ITERATION: `homeHeaderIteration`,
  CITIES_KEY: `CitiesRefinement`,
  COURSE_SESSION_SELECTOR_STATE_KEY: `courseSessionSelectorState`,
  PUBLICATIONS_LIST_PAGE: `publicationsListPage`,
  CLOSED_LIST_LENGTH: 4,
  LIST_THRESHOLD: 6,
}

export const COURSE = {
  PUBLIC: `public`,
  PRIVATE: `private`,
  VIRTUAL: `virtual`,
}

export const COURSE_TYPE = {
  REGULAR: `Regular`,
  CONFERENCE: `Conference`,
  PROGRAM: `Program`,
  SELF_PACED: `SelfPaced`,
  EXTERNAL_REGISTRATION: `ExternalRegistration`,
}

// match SAFIRE values
export const SESSION_STATUS = {
  CALENDAR: `Au calendrier`,
  PROSPECT: `Prospect`,
  CONFIRMED: `Confirmée`,
}

// match SAFIRE values
export const TEACHER = {
  EXCLUSIVE: `Entente d'exclusivité`,
  FAVORITE: `Formateur favori`,
  NON_APPLICABLE: `Non applicable`,
  READY: `Prêt`,
}

export const CITY = {
  Québec: { position: 1 },
  Montréal: { position: 2 },
}

export const VIRTUAL_CITY = `Classe virtuelle`

export const FORM = {
  NOTIFIY_WHEN_COURSE_SCHEDULED: `NotifiyWhenCourseScheduled`,
  NOTIFY_WHEN_SESSION_CONFIRMED: `NotifyWhenSessionConfirmed`,
  REGISTER_ON_MOBILE: `RegisterOnMobile`,
  REQUEST_INFORMATION: `RequestInformation`,
  REGISTER_SPECIAL_OFFER: `RegisterSpecialOffer`,
  REQUEST_QUOTE: `RequestQuote`,
  REQUEST_QUOTATION: `RequestQuotation`,
  REQUEST_INFORMATION_ESI: `RequestInformationMicrosoftESI`,
  REQUEST_INFORMATION_GENERAL_ESI: `RequestInformationGeneralMicrosoftESI`,
}

export const TEL = `18776242344`

export const DOWNLOAD_CATALOG = {
  URL: {
    fr: `/AFI-Explore-2023.pdf`,
    en: `/AFI-Explore-2023-EN.pdf`,
  },
  MAILCHIMP_LIST_ID: `2915729184`,
}
