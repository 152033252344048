export default {
  placeholder: `Trouver une formation par mots-clés`,
  homeTitle: `ou chercher parmi nos thématiques :`,
  ariaLabel: `Recherche`,
  price: `Prix`,
  noCourseFound: `Aucun résultat.`,
  searchPlaceholder: 'Inscrire vos mots-clés',
  durationInDays: `{{count}} jour`,
  // eslint-disable-next-line @typescript-eslint/camelcase
  durationInDays_plural: `{{count}} jours`,
}
