export default {
  title: {
    unfoundSanityCourses: `« ID des cours » inexistants dans les catégories Sanity`,
    orphanSafireCourses: `« Cours Safire » orphelins (non catégorisés dans Sanity)`,
  },
  header: {
    contentType: `Content Type`,
    courseID: `Course ID`,
    courseName: `Course Name`,
    path: `Page`,
  },
}
