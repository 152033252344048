export default {
  home: `Home`,
  catalog: `Trainings`,

  learningPaths: {
    title: `Learning Paths`,
    aws: {
      title: `AWS Training and Certification`,
      url: `/en/aws-training-certification`,
    },
    projectManagement: {
      title: `Project Management`,
      url: `/en/project-management`,
    },
  },

  confidentiality: `Confidentiality`,
  confidentialityUrl: `/en/confidentiality`,
}
