export default {
  home: `Accueil`,
  catalog: `Formations`,

  learningPaths: {
    title: `Parcours`,
    aws: {
      title: `Formation et certification AWS`,
      url: `/fr/aws-formation-certification`,
    },
    projectManagement: {
      title: `Formation et certification en gestion de projet`,
      url: `/fr/gestion-de-projet`,
    },
  },

  confidentiality: `Confidentialité`,
  confidentialityUrl: `/fr/confidentialite`,
}
