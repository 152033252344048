export default {
  title: {
    unfoundSanityCourses: `« ID des cours » inexistants dans les catégories Sanity`,
    orphanSafireCourses: `« Cours Safire » orphelins (non catégorisés dans Sanity)`,
  },
  header: {
    contentType: `Type de contenu`,
    courseID: `ID du cours`,
    courseName: `Nom du cours`,
    path: `Page`,
  },
}
