import React, { ReactNode, useEffect, useState } from 'react'
import { ToggleDrawerContextType } from '@components/context/MenuDrawerContext'

export type Props = {
  children: ReactNode
}

const NavDrawerContext = React.createContext<ToggleDrawerContextType>({
  opened: false,
  setOpened: function(open: boolean) {
    this.opened = open
  },
})

function NavDrawerContextProvider({ children }: Props) {
  const [opened, setOpened] = useState(false)
  const value = { opened, setOpened }

  useEffect(() => {
    document.body.dataset.isNavDrawerOpened = opened ? `opened` : `closed`
  }, [opened])

  return <NavDrawerContext.Provider value={value}>{children}</NavDrawerContext.Provider>
}

export { NavDrawerContext, NavDrawerContextProvider }
