export default {
  title: `Stay in touch with us`,
  description: `Receive news and updates on AFI courses and events.`,
  cta: `Sign up for our newsletter to`,
  summary: {
    one: `Discover our trainings, learning paths and solutions`,
    two: `Be aware of trends, innovations and best practices`,
    three: `Find out about our experts' advices`,
    four: `Be the first to get special offers and exclusive information!`,
  },
  subscribebutton: `Subscribe to the newsletter`,
}
