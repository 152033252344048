export default {
  allowSubscriptionToNewsletter: `Yes, I would like to receive the AFI Expertise newsletter.`,
  invalidEmail: `Invalid email`,
  requiredField: `Required field`,
  sendFail: `An error occurred while sending. Try again or call us.`,
  sendSuccess: `Your message has been sent! `,

  requestInformation: {
    title: `Do you have questions about this training?`,
    titlePlural: `Do you have questions about those trainings?`,
    presentation: `One of our consultants will get back to you as soon as possible`,
    presentationSingular: `Your advisor will get back to you as soon as possible.`,
    firstName: `First Name`,
    lastName: `Last Name`,
    phone: `Phone`,
    extension: `Extension`,
    email: `Email`,
    message: `Explain how we might help you `,
    submit: `Send your request`,
  },

  notifyWhenSessionConfirmed: {
    title: `Email notification`,
    presentation: `You may now be notified when a date is confirmed. Just choose a location and a date in the drop-down menu and we’ll notify you as soon as the confirmation process begins.`,
    email: `Email`,
    submit: `Notify me`,
  },

  notifiyWhenCourseScheduled: {
    title: `Open a new date in the schedule`,
    presentation: `Our schedule does not meet your needs? Let us know what your preferences are and we’ll let you know as soon as a new session is added.`,
    preferences: `Preferences`,
    q1: `January to March`,
    q2: `April to June`,
    q3: `July to September`,
    q4: `October to December`,
    email: `Email`,
    submit: `Notify me`,
  },

  requestQuote: {
    title: `Ask for a quote`,
    presentation: `Fill out the request for quote above and one of our consultants will get back to you as soon as possible.`,
  },

  requestQuotation: {
    title: `Ask for a quotation`,
    presentation: `Choose a location and a date in the drop-down menu then fill in the request for quotation above. One of our consultants will get back to you as soon as possible.`,
  },

  requestSimple: {
    title: `Book my place`,
    note: `Note that online transactions are only available on the full-screen version of the site.`,
    text: `To reserve a space, please fill out the form below and we will contact you within 48 to complete your registration.`,
    requiredFields: `Required field.`,
    courseTitle: `Training title:`,
    sessionsDates: `Date:`,
    sessionsDates_plural: `Dates:`,
    location: `Attendance:`,
    virtual: `Virtual class`,
    email: `Email`,
    fullName: `Name`,
    phone: `Phone`,
    extension: `Extension`,
    company: `Entreprise`,
    message: `Message`,
    submit: `Call me`,
  },

  quoteForm: {
    firstName: `First Name`,
    lastName: `Last Name`,
    company: `Entreprise`,
    participantCount: `Number of participants`,
    phone: `Phone`,
    extension: `Extension`,
    email: `Email`,
    message: `Explain your request`,
    sendToSupervisor: `Send a copy of the quote to my employer`,
    submit: `Send the request`,
  },

  downloadCatalog: {
    placeholder: `Your email here`,
    success: `Check your download folder`,
  },

  registerSpecialOffer: {
    title: `Enroll participants`,
    presentation: `Fill out the form below to register for this course.`,
    firstName: `First Name`,
    lastName: `Last Name`,
    email: `Email`,
    submit: `Send the request`,
    addLearner: `Add a participant`,
    managerContactDetails: "Manager's contact details",
    participantsContactDetails: `Participants contact details`,
    activitySector: `Business sector`,
    participant: `Participant`,
    atLeastOneLearner: 'You must add at least one participant',
    noMoreThanTwelveLearners: 'You cannot add more than twelve participants',
    select: `Business Sector`,
  },
}
