import { cx } from 'classix'
import { Link, graphql, useStaticQuery } from 'gatsby'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import React from 'react'
import { useTranslation } from 'react-i18next'

import afiUBlackLogo from '@src/assets/svg/afiU-logo-black.svg'
import linkedInLogo from '@src/assets/svg/socials/linkedin.svg'
import i18n from '@src/config/i18n'
import locales from '@src/config/locales'
import { AfiUTheme } from '@src/utils/types'

import Container from './Container'
import FooterColumn from './FooterColumn'
import FooterMobileColumn from './FooterMobileColumn'
import LocaleSelector from './LocaleSelector'

export type FooterColumnData = {
  title: string
  rootUrl?: string
  items: { title: string; href: string }[]
}

type PartialAfiUTheme = Pick<AfiUTheme, 'id' | 'slug' | 'title'>

export default function Footer() {
  const { t } = useTranslation('revamp')
  const breakpoints = useBreakpoint()
  const locale = i18n.language
  const result = useStaticQuery<{ themes: { nodes: PartialAfiUTheme[] } }>(graphql`
    query {
      themes: allSanityAfiUTheme(filter: { active: { eq: true } }) {
        nodes {
          id
          slug {
            fr {
              current
            }
            en {
              current
            }
          }
          title {
            fr
            en
          }
        }
      }
    }
  `)

  const themes = result.themes.nodes.sort((a, b) => a.title.fr.localeCompare(b.title.fr))

  const websiteColumns: FooterColumnData[] = [
    {
      title: t('forIndividuals'),
      items: [
        { title: t('businessPriorities'), href: locales[locale].programsPath },
        { title: t('offShelfTrainings'), href: locales[locale].catalogPath },
        { title: t('afiUCircles'), href: locales[locale].circlesPath },
      ],
    },
    {
      title: t('forOrganizations'),
      items: [
        {
          title: t('privateDelivery'),
          href: `${locales[locale].organizationsPath}#groupes`,
        },
        {
          title: t('learningWorkshops'),
          href: `${locales[locale].organizationsPath}#ateliers`,
        },
        {
          title: t('businessCoaching'),
          href: `${locales[locale].organizationsPath}#coaching`,
        },
        {
          title: t('customPrograms'),
          href: `${locales[locale].organizationsPath}#programmes`,
        },
      ],
    },
    {
      title: t('businessPriorities'),
      items: themes.map((theme) => ({
        title: theme.title[locale],
        href: locales[locale].themePathFactory(theme.slug[locale].current),
      })),
    },
    {
      title: t('certifiedTrainings'),
      items: [
        { title: 'Microsoft', href: `${locales[locale].catalogPath}/microsoft` },
        { title: 'VMware', href: `${locales[locale].catalogPath}/vmware` },
        { title: 'AWS', href: `${locales[locale].catalogPath}/aws` },
        { title: 'Cisco', href: `${locales[locale].catalogPath}/cisco` },
        { title: 'CompTIA', href: `${locales[locale].catalogPath}/comptia` },
        { title: 'ITIL', href: `${locales[locale].catalogPath}/itil` },
      ],
    },
    {
      title: t('aboutUs'),
      items: [
        {
          title: t('aboutUsTitle'),
          href: locales[locale].aboutUsPath,
        },
        { title: t('afiUExperience'), href: locales[locale].vivreAfiUPath },
        { title: t('theTeam'), href: locales[locale].teamPath },
        { title: t('ourOffices'), href: locales[locale].ourOfficesPath },
        { title: t('blog'), href: locales[locale].blogPath },
        {
          title: t('partnersAndCertifications'),
          href: locales[locale].partnersCertificationsPath,
        },
        { title: t('careers'), href: locales[locale].careersPath },
        { title: t('faq'), href: locales[locale].faqPath },
        { title: t('contact'), href: t('contactUrl') },
        { title: t('fundingPrograms'), href: locales[locale].fundingPath },
      ],
    },
  ]
  const laFamilleEdgendaColumns: FooterColumnData[] = [
    {
      title: t('edgenda'),
      rootUrl: t('edgendaRootUrl'),
      items: [
        { title: t('strategyOperations'), href: t('edgendaStrategyUrl') },
        { title: t('coachingLeadership'), href: t('edgendaCoachingUrl') },
        { title: t('emergingTechnologies'), href: t('edgendaTechnologyUrl') },
        { title: t('learning'), href: t('edgendaLearningUrl') },
      ],
    },
    {
      title: t('afiByEdgenda'),
      rootUrl: t('afiByEdgendaRootUrl'),
      items: [
        { title: t('designDevelopment'), href: t('afiByEdgendaLearningExperiencesUrl') },
        {
          title: t('rapidELearning'),
          href: t('afiByEdgendaFastNumericLearningsUrl'),
        },
        { title: t('customPrograms'), href: t('afiByEdgendaPersonalizedProgramsUrl') },
        { title: t('businessCoaching'), href: t('afiByEdgendaCoachingUrl') },
      ],
    },
    {
      title: t('apprentxByEdgenda'),
      rootUrl: t('apprentxRootUrl'),
      items: [{ title: 'B12', href: t('apprentxB12Url') }],
    },
    {
      title: t('afiU'),
      rootUrl: locales[locale].homePath,
      items: [
        { title: t('forIndividuals'), href: locales[locale].catalogPath },
        { title: t('forOrganizations'), href: locales[locale].organizationsPath },
        { title: t('afiUExperience'), href: locales[locale].vivreAfiUPath },
      ],
    },
  ]

  return (
    <Container tag='footer' className='bg-white pt-12 font-avenir flex flex-col'>
      {breakpoints.md ? (
        <>
          <div className='flex flex-row flex-wrap items-end mb-8'>
            <LocaleSelector className='ml-auto text-black hover:text-black hover:underline' />
          </div>
        </>
      ) : (
        <Newsletter className='mb-8 ml-auto' style={{ maxWidth: '500px' }} />
      )}
      {breakpoints.md ? (
        <>
          <div className='flex flex-row items-start'>
            <div className='flex items-start mr-7' style={{ width: '220px' }}>
              <img src={afiUBlackLogo} />
            </div>
            <div className='flex flex-row gap-7 flex-wrap'>
              <div className='flex flex-col gap-8'>
                {websiteColumns.slice(0, 2).map((column) => (
                  <FooterColumn key={column.title} column={column} />
                ))}
              </div>
              {websiteColumns.slice(2).map((column) => (
                <FooterColumn key={column.title} column={column} />
              ))}
            </div>
          </div>
          <hr className='mt-16 mb-12' />
        </>
      ) : (
        <div className='flex flex-col gap-2'>
          <img className='ml-auto' src={afiUBlackLogo} />
          {websiteColumns.map((column) => (
            <FooterMobileColumn key={column.title} column={column} />
          ))}
          <hr className='border-black mt-4 mb-6' />
        </div>
      )}
      <h2 className='text-r-2xl mb-2 block md3:hidden'>{t('edgendaFamily')}</h2>
      {breakpoints.md ? (
        <div className='flex flex-row items-start'>
          <div className='mr-7' style={{ width: '220px' }}>
            <h2 className='text-r-2xl' style={{ maxWidth: '12ch' }}>
              {t('edgendaFamily')}
            </h2>
          </div>

          <div className='flex flex-row gap-7 flex-wrap'>
            {laFamilleEdgendaColumns.map((column) => (
              <FooterColumn key={column.title} column={column} />
            ))}
          </div>
        </div>
      ) : (
        <div className='flex flex-col gap-2 items-start'>
          {laFamilleEdgendaColumns.map((column) => (
            <FooterMobileColumn key={column.title} column={column} />
          ))}
          <hr className='border-black mt-4 mb-6 w-full' />
          <LocaleSelector className='text-black hover:text-black hover:underline' />
        </div>
      )}
      <div className='mb-10 mt-10 md:mt-20 flex flex-row gap-x-12'>
        <div className='flex w-full md:w-3/5 flex-col'>
          <div className='flex flex-col md3:flex-row items-start justify-between gap-x-10 gap-y-4'>
            <div>
              <p className='text-r-lg mb-4'>
                {t('needAssistance')}{' '}
                <a className='text-black underline' href={t('takeMeetingUrl')}>
                  {t('talkSales')}
                </a>
                {` ${t('orCall')} `}
                <a className='text-black underline' href='tel:+18776242344'>
                  1 877 624.2344
                </a>
              </p>
            </div>
            <div className='order-first md3:order-last flex flex-row items-center gap-6 pr-5'>
              <a className='flex-shrink-0' href='https://www.linkedin.com/company/afi-u/'>
                <img src={linkedInLogo} alt='LinkedIn' />
              </a>
            </div>
          </div>
          <hr />
          <p className='text-r-sm mt-2'>
            <Link className='underline mt-2' to={locales[locale].confidentialityPath}>
              {t('privacyPolicy')}
            </Link>
            {` | ${t('privacyText')}`}
          </p>
          <p className='text-r-sm mt-2'>{t('edgendaCopyright')}</p>
          <a className='cky-banner-element cursor-pointer text-xs mt-2'>
            {t('revamp:cookieSettings')}
          </a>
        </div>
        {breakpoints.md && <Newsletter className='ml-auto' />}
      </div>
    </Container>
  )
}

function Newsletter({ className, ...rest }: React.HTMLAttributes<HTMLDivElement>) {
  const { t } = useTranslation('revamp')

  return (
    <div className={cx('flex flex-col gap-y-2 items-end', className)} {...rest}>
      <p className='text-sm font-bold' style={{ textAlign: 'end', maxWidth: '35ch' }}>
        {t('newsletterText')}
      </p>
      <a
        href={t('newsletterLink')}
        className='text-r-base bg-black text-white rounded-sm hover:bg-revamp-neutral-600 font-bold hover:text-white px-4 py-1'
      >
        {t('newsletterSubscribe')}
      </a>
    </div>
  )
}
