export default {
  navigationPath: `/en/leadership-development-programs`,
  navigation: ` Leadership Development Programs`,
  hero: { first: `Ready to`, second: `lead in today’s workplace.` },
  heroIntroduction: {
    title: `We don’t teach leadership. You experience it. `,
    description: `AFI leadership programs combine skills and behavioral development as well as technology adoption to make sure you generate a positive impact on your team and your organization. No matter how the workplace evolves, you will be ready.`,
    step: {
      title: {
        action: `Action`,
        impact: `Impact`,
        coaching: `Coaching`,
        community: `Community`,
      },
      description: {
        action: `#Learning# by doing`,
        impact: `#Measurable outcomes# backed by science and technology`,
        coaching: `#Experienced# facilitators and business coaches`,
        community: `A group of #committed alumni#, business leaders and coaches`,
      },
    },
  },
  programsIntro: {
    title: `Maximize everyone’s contribution to business success.`,
    description: `Today’s organizations need leaders that are prepared to communicate clearly, embrace and drive change, attract top talent and create a culture of innovation and performance. Whether you are a professional, new manager, senior leader or executive, our programs will help you develop the skills and confidence to generate the positive impact you are looking for.`,
  },
  program: `Program`,
  'learn-more': `For more information`,
  programs: {
    'leadership-team-management': {
      title: `Leadership and Team Management`,
      description1: `Be prepared to take on greater responsibility and create new career opportunities by improving your personal leadership skills. `,
      description2: `For new managers or professionals seeking to increase their self-awareness and develop essential leadership skills to mobilize their team in the modern workplace.`,
      link: `https://www.afiexpertise.com/en/training/leadership-and-team-management-1842`,
    },
    'leadership-coach': {
      title: `Leader-Coach`,
      description1: `Be prepared to elevate your employees to their highest potential in a work environment that is liable to change frequently and characterized by instability and complexity.`,
      description2: `For managers and leaders seeking to empower their team for greater autonomy and agility by adopting a leader-coach posture within their organization.`,
      link: `https://www.afiexpertise.com/en/training/leader-as-coach-add-coaching-to-your-leadership-skills-2111`,
    },
    'leadership-executive': {
      title: `Executive Leadership`,
      description1: `Be prepared to enhance your leadership role as a manager, leader and coach to seize business opportunities, stimulate greater creativity and innovation, and drive organizational growth.`,
      description2: `For executives and senior leaders seeking to thrive at the highest levels by leading a more flexible, team-centric, and agile organization.`,
      link: `https://www.afiexpertise.com/en/training/executive-leadership-program-2017`,
    },
  },
  stats: {
    stat1: `managers and leaders#certified by AFI`,
    stat2: `organizations empowered by our #alumni network`,
  },
  approach: {
    title: `Learning by doing`,
    description1: `We believe that sustainable learning occurs when content is connected to your business context and reinforced by technology.`,
    description2: `Our goal is to help you develop a leadership style that generates sustainable results across the organization.`,
  },
  b12: {
    title: `Your potential elevated by science and by our technology`,
    description1: `
    B12 draws on cognitive science and artificial intelligence to increase the impact of our programs. It’s like a gym for deepening new knowledge, adopting new behavior patterns, evaluating outcomes, and accelerating on the job application. 
    `,
    lists: {
      '1': `Greater learner engagement`,
      '2': `Increased knowledge retention `,
      '3': `Effective skills transfer`,
      '4': `Ongoing measurable results`,
      '5': `Habit formation and behavior change`,
    },
    description2: ` `,
  },
  'private-formula': {
    title: `Private Group Training`,
    description1: `Our leadership development programs can be tailored to your business context and delivered from the location of your choice.`,
    contact: `Request a quote`,
    videoUrl: `https://www.youtube.com/embed/D1-x5pnxhS4`,
  },
  'contact-us': {
    title: `Explore how you can make a difference`,
    contact: `Contact Us`,
  },
  modal: {
    title: `One of our expert will contact you shortly to discuss your leadership development needs.`,
    'first-name': `First Name`,
    'last-name': `Last Name`,
    email: `Email Address`,
    company: `Company`,
    'phone-number': `Phone number`,
    'contact-me': `Contact me`,
  },
  nav: {
    program: `Programs`,
    approach: `Approach`,
    'private-formula': `Group Training`,
    'contact-us': `Contact Us`,
    back: `Back`,
  },
}
