import React, { ReactNode, useEffect, useState } from 'react'

import { VIRTUAL_CITY } from '@src/config/constants'
import { GraphqlCourseType, SessionType } from '@src/interfaces/GraphqlCourseType'

type NullableCourseType = GraphqlCourseType | null
type NullableSessionType = SessionType | null

export type Props = {
  children: ReactNode
}
export type CourseFormContextType = {
  opened: boolean
  virtual: boolean
  form: string
  course: NullableCourseType
  session: NullableSessionType
  contactEmail: string | undefined
  setOpened: (open: boolean) => void
  setVirtual: (virtual: boolean) => void
  setForm: (form: string) => void
  setCourse: (course: GraphqlCourseType) => void
  setSession: (session: NullableSessionType) => void
  setContactEmail: (contactEmail: string) => void
}

const CourseFormContext = React.createContext<CourseFormContextType>({
  opened: false,
  virtual: false,
  form: ``,
  course: null,
  session: null,
  contactEmail: undefined,

  setOpened: function (open: boolean) {
    this.opened = open
  },
  setVirtual: function (virtual: boolean) {
    this.virtual = virtual
  },
  setForm: function (form: string) {
    this.form = form
  },
  setCourse: function (course: GraphqlCourseType) {
    this.course = course
  },
  setSession: function (session: NullableSessionType) {
    this.session = session
  },
  setContactEmail: function (contactEmail: string) {
    this.contactEmail = contactEmail
  },
})

function CourseFormContextProvider({ children }: Props): JSX.Element {
  const [opened, setOpened] = useState(false)
  const [virtual, setVirtual] = useState(false)
  const [form, setForm] = useState<string>(``)
  const [course, setCourse] = useState<NullableCourseType>(null)
  const [session, setSession] = useState<NullableSessionType>(null)
  const [contactEmail, setContactEmail] = useState<string | undefined>(undefined)
  const value = {
    opened,
    setOpened,
    virtual,
    setVirtual,
    form,
    setForm,
    course,
    setCourse,
    session,
    setSession,
    contactEmail,
    setContactEmail,
  }

  useEffect(() => {
    setOpened(!!form)
  }, [form])

  useEffect(() => {
    if (session && session.City === VIRTUAL_CITY) {
      setVirtual(true)
    }
  }, [session])

  return <CourseFormContext.Provider value={value}>{children}</CourseFormContext.Provider>
}

export { CourseFormContext, CourseFormContextProvider }
