export default {
  navigationPath: `/fr/programmes-developpement-leadership`,
  navigation: `Programmes de développement du leadership`,
  hero: { first: `Prêts à`, second: `diriger le monde d'aujourd'hui.` },
  heroIntroduction: {
    title: `Nous n’enseignons pas le leadership, nous le faisons vivre`,
    description: `Les programmes d’AFI rallient développement de compétences, adoption de
    comportements et intégration de nouveaux outils pour optimiser l’impact de votre
    leadership. Peu importe l’évolution du contexte, vous serez prêts.`,
    step: {
      title: {
        action: `Action`,
        impact: `Impact`,
        coaching: `Coaching`,
        community: `Communauté`,
      },
      description: {
        action: `Des #apprentissages# par l'action`,
        impact: `Des #résultats mesurables# propulsés par la science et par
        la technologie`,
        coaching: `Des facilitateurs-coachs #d’expérience#`,
        community: `Un cercle de leaders et de coachs d’affaires #engagés#`,
      },
    },
  },
  programsIntro: {
    title: `Maximiser la contribution de chacun au succès de l’organisation.`,
    description: `Les organisations d’aujourd’hui ont besoin de leaders toujours prêts à communiquer
  clairement, à accompagner le changement et à faciliter l’émergence d’une culture
  d'innovation et de performance. Que vous soyez un professionnel, un nouveau
  gestionnaire, un leader d’expertise ou un haut-dirigeant, nos programmes vous
  permettent de développer les compétences et la confiance pour agir en leader positif
  sur votre environnement.`,
  },
  program: `Programme`,
  'learn-more': `En savoir plus`,
  programs: {
    'leadership-team-management': {
      title: `Leadership et gestion d'équipe`,
      description1: `Être prêt à prendre plus de responsabilités et à s’ouvrir à de nouvelles
      opportunités de carrière en améliorant ses compétences personnelles en
      leadership.`,
      description2: `Pour les nouveaux gestionnaires ou les professionnels désirant améliorer leur
      conscience de soi et développer ou renforcer les compétences essentielles pour
      motiver les équipes dans un environnement de travail moderne.`,
      link: `https://www.afiexpertise.com/fr/formation/leadership-et-gestion-dequipe-1842`,
    },
    'leadership-coach': {
      title: `Leader-coach`,
      description1: `Être prêt à élever le potentiel de son équipe  pour faire face à un environnement d’affaires de plus en plus complexe et en continuel changement.`,
      description2: `Pour les gestionnaires et les leaders désirant faciliter la responsabilisation, l’autonomie et l’agilité de leurs équipes en adoptant une posture de leader-coach dans un contexte organisationnel.`,
      link: `https://www.afiexpertise.com/fr/formation/devenir-leader-coach-habiletes-de-coaching-pour-gestionnaires-2111`,
    },
    'leadership-executive': {
      title: `Leadership exécutif`,
      description1: `Être prêt à adopter différentes approches en leadership – gestionnaire, leader et coach – pour saisir les opportunités d’affaires, stimuler l’innovation et soutenir la croissance de son organisation.`,
      description2: `Pour les haut-dirigeants et les gestionnaires d’expérience désirant augmenter leur impact transversal et faciliter l’émergence d’une culture organisationnelle agile.`,
      link: `https://www.afiexpertise.com/fr/formation/parcours-leadership-executif-2017`,
    },
  },
  stats: {
    stat1: `gestionnaires et professionnels #
    certifiés par AFI`,
    stat2: `organisations propulsées par #
    nos diplômés`,
  },
  approach: {
    title: `Pour maîtriser une compétence - il faut la pratiquer.`,
    description1: `Notre croyance dans l'apprentissage par la pratique soutenue par la technologie et
    connecté à vos enjeux d’affaires va au-delà d'une philosophie éducative.`,
    description2: `Notre objectif - vous accompagner dans l’adoption et la mise en pratique des
    comportements clés du leadership, pour transformer les individus de façon
    durable.`,
  },
  b12: {
    title: `Votre potentiel propulsé par la science et par notre technologie`,
    description1: `Notre plateforme B12 utilise la science cognitive et l’intelligence artificielle
  pour augmenter l’impact de nos programmes. C’est un gym pour renforcer le transfert
  des nouvelles connaissances, développer de nouvelles habitudes, mesurer les
  résultats et accélérer l’utilisation au travail.`,
    description2: `Notre plateforme B12 utilise la science cognitive et l’intelligence artificielle
  pour augmenter l’impact de nos programmes. C’est un gym pour renforcer le transfert
  des nouvelles connaissances, développer de nouvelles habitudes, mesurer les
  résultats et accélérer l’utilisation au travail.`,
    lists: {
      '1': `Meilleur engagement des participants`,
      '2': `Rétention et renforcement des connaissances`,
      '3': `Transfert des apprentissages efficace sur le terrain`,
      '4': `Mesure de performance stratégique et suivi de l’évolution`,
      '5': `Adoption des habitudes et des comportements qui mènent au succès`,
    },
  },
  'private-formula': {
    title: `Formule privée`,
    description1: `Nos programmes sont disponibles en session privée ou personnalisée selon votre contexte et
    vos besoins.`,
    description2: `Il peut être offert à l’un de nos centres de formation/salles expérientielles ou directement
    à vos bureaux.`,
    contact: `Contactez un conseiller`,
    videoUrl: `https://www.youtube.com/embed/D1-x5pnxhS4`,
  },
  'contact-us': {
    title: `Explorez comment nos programmes peuvent propulser votre croissance.`,
    contact: `Contactez un conseiller`,
  },
  modal: {
    title: `Un conseiller vous contactera sous peu pour discuter de vos besoins en développement du
    leadership.`,
    'first-name': `Prénom`,
    'last-name': `Nom`,
    email: `Addresse courriel`,
    company: `Compagnie`,
    'phone-number': `Numéro de téléphone`,
    'contact-me': `Contactez-moi`,
  },
  nav: {
    program: `Programmes`,
    approach: `Approche`,
    'private-formula': `Formule privée`,
    'contact-us': `Contactez-nous`,
    back: `Retour`,
  },
}
