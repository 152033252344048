export default {
  close: `Close <`,
  moreDetails: `Learn more >`,
  firstTenEcho: {
    descTitle: `Special offer`,
    descShort: `Attend a guarantee to run AWS training and leave with an Amazon Echo speaker.`,
    descLongOne: `Be one of the 10 first students to participate to our confirmed AWS courses and go back home with not only new skills, but also an Amazon Echo speaker.`,
    descLongTwo: ``,
    descLongThree: `What is an Echo?`,
    descLongFour: `It’s a hands-free voice-controlled speaker. Echo connects to the Alexa Voice Service to play music, ask questions, make calls, send and receive messages, give information, read you news and sports results, weather and more.`,
    linkUrl: `/en/training/catalog/aws`,
    linkLabel: `Enroll now in one of our scheduled trainings >`,
    rulesUrl: `/en/promo/aws-echo-speaker/contest-rules`,
    rulesLabel: `Contest rules >`,
  },
  microsoftESI: {
    pageTitle: `Develop your skills and knowledge`,
    pageIntro: `As an official partner of Microsoft’s Enterprise Skills Initiative program, Afi\xa0U. is proud to help you accelerate your transformation and achieve your goals by facilitating the development of your team’s skills. Our expert trainers will help you develop the skills you need to quickly take advantage of Microsoft Azure and Artificial Intelligence platforms and services. `,
    listTitle: `Program courses`,
    headerHome: `Technical skills <br />for business`,
    header: `Enterprise Skills Initiative`,
    view: `View`,
    back: `Back to Program Courses`,
    price: `Price`,
    yourAdvisor: `Your advisor`,
    yourAdvisorFemale: `Your advisor`,
    distinctionAfiU: "Afi\xa0U.'s Disctinction",
    distinctionAfiUList: [
      '100% Canadian-based company',
      'Instructor-led in-person or virtual sessions',
      'Team of in-house certified Azure trainers',
      'Courses available in English or in French',
    ],
    requestInformation: 'Information or registration request',
    ctaLink: 'https://lp.afiexpertise.com/contactez-nous-esi',
  },
  virtual: {
    descTitle: `Promo code VIRT40`,
    descShort: `Get 40% off on all our guaranteed-to-run virtual classes.`,
    linkUrl: `/en/training/catalog`,
  },
  subvention: {
    descTitle: `Training program financed up to 50%`,
    descShort: ``,
    linkLabel: `See available funding programs`,
    linkUrl: `/en/solutions/funding-programs`,
  },
}
