import styled from '@emotion/styled'
import { Link } from 'gatsby'
import React from 'react'
import tw from 'tailwind.macro'

import LocaleSwitcher from '@components/layout/LocaleSwitcher'
import Container from '@components/revamp/Container'

import { theme } from '@src/../tailwind.config'
import afiUBlackLogo from '@src/assets/svg/afiU-logo-black.svg'
import i18n from '@src/config/i18n'
import locales from '@src/config/locales'

const HeaderContainer = styled(`header`)`
  ${tw`flex justify-center mb-8`}
  background-color: #2f2f2f;
`
const HeaderContent = styled(`div`)`
  width: 100%;
  max-width: ${theme.screens.xl};
`

const HeaderESI = () => {
  const locale = i18n.language

  return (
    <div className='w-full'>
      <Container className='flex py-4'>
        <div>
          <Link to={locales[locale].homePath}>
            <img src={afiUBlackLogo} />
          </Link>
        </div>
        <div className='ml-auto flex'>
          <img
            width='182'
            height='62'
            src='/page/microsoft-esi/gold-microsoft-partner@2x.png'
            alt='Gold Microsoft Partner'
          />
          <div className='w-6 text-right ml-2 sm:ml-8 text-xs'>
            <LocaleSwitcher isDark={false} />
          </div>
        </div>
      </Container>
      <HeaderContainer>
        <HeaderContent>
          <img
            srcSet={`/page/microsoft-esi/MS-ESI-768.png 768w,
                  /page/microsoft-esi/MS-ESI-1440.png 1440w`}
            src={`/page/microsoft-esi/MS-ESI-768.png`}
            alt='Microsoft Enterprise Skill Initiative'
          />
        </HeaderContent>
      </HeaderContainer>
    </div>
  )
}

export default HeaderESI
