export default {
  featured: {
    action: `Voir`,
  },
  headerTitle: `Toujours apprendre`,
  imageLegend: {
    chouakri: `VP Technologies émergentes, coach et formateur`,
    demers: `Vice-présidente, Coach et facilitatrice en Leadership`,
    mangones: `Coach, formatrice et conseillère sénior`,
    paradis: `Expert et formateur Microsoft`,
  },
  partnersCertifications: {
    title: `AFI, partenaire officiel des leaders du marché`,
    text: `AFI offre des formations reconnues par plusieurs organismes et ordres professionnels.`,
    cta: `Voir tous nos partenaires`,
  },
  readMore: `Lire`,
  testimonials: `Ce que j’ai le plus aimé de ma formation`,
}
