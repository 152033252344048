export default {
  allowSubscriptionToNewsletter: `Oui, je désire recevoir l’infolettre d’AFI Expertise.`,
  invalidEmail: `Courriel non valide`,
  requiredField: `Ce champ est requis`,
  sendFail: `Une erreur est survenue lors de l’envoi. Essayez à nouveau ou contactez-nous par téléphone.`,
  sendSuccess: `Votre message a été envoyé avec succès!`,

  requestInformation: {
    title: `Des questions sur cette formation?`,
    titlePlural: `Des questions sur ces formations?`,
    presentation: `Un de nos conseillers vous répondra dans les plus brefs délais.`,
    presentationSingular: `Votre conseiller vous répondra dans les plus brefs délais.`,
    firstName: `Prénom`,
    lastName: `Nom`,
    phone: `Téléphone`,
    extension: `Poste`,
    email: `Courriel`,
    message: `Précisez votre demande`,
    submit: `Envoyez la demande`,
  },

  notifyWhenSessionConfirmed: {
    title: `Notification par courriel`,
    presentation: `Vous pouvez maintenant être notifiés lorsqu’une date se confirme. Il suffit de sélectionner un lieu et une date dans le menu déroulant et d'inscrire votre adresse courriel. Nous vous aviserons dès que votre choix sera en voie d’être confirmé.`,
    email: `Courriel`,
    submit: `Notifiez-moi`,
  },

  notifiyWhenCourseScheduled: {
    title: `Ouverture de session`,
    presentation: `Informez-nous de votre intérêt et nous allons évaluer les possibilités d’ouvrir de nouvelles dates.`,
    preferences: `Préférences`,
    q1: `Janvier à mars`,
    q2: `Avril à juin`,
    q3: `Juillet à septembre`,
    q4: `Octobre à décembre`,
    email: `Courriel`,
    submit: `Envoyez`,
  },

  requestQuote: {
    title: `Demande de soumission`,
    presentation: `Complétez la demande de soumission ci-dessous et un de nos conseillers vous répondra dans les plus brefs délais.`,
  },

  requestQuotation: {
    title: `Demande de devis`,
    presentation: `Sélectionnez un lieu et une date dans le menu déroulant puis complétez le formulaire ci-dessous. Un de nos conseillers vous répondra dans les plus brefs délais.`,
  },

  requestSimple: {
    title: `Inscription simplifiée`,
    note: `Notez que les transactions en ligne ne sont disponibles que sur la version grand écran du site.`,
    text: `Pour réserver votre place à l’une de nos sessions, veuillez remplir le formulaire ci-bas et nous communiquerons avec vous dans les 48 heures pour compléter votre inscription.`,
    requiredFields: `Champs requis.`,
    courseTitle: `Titre du cours :`,
    sessionsDates: `Date :`,
    sessionsDates_plural: `Dates :`,
    location: `Présence :`,
    virtual: `Classe virtuelle`,
    email: `Courriel`,
    fullName: `Nom`,
    phone: `Téléphone`,
    extension: `Poste`,
    company: `Entreprise`,
    message: `Message`,
    submit: `Appelez-moi`,
  },

  quoteForm: {
    firstName: `Prénom`,
    lastName: `Nom`,
    company: `Entreprise`,
    participantCount: `Nombre de participants`,
    phone: `Téléphone`,
    extension: `Poste`,
    email: `Courriel`,
    message: `Précisez votre demande`,
    sendToSupervisor: `Envoyez une copie du devis à mon employeur`,
    submit: `Envoyez la demande`,
  },

  downloadCatalog: {
    placeholder: `Votre courriel ici`,
    success: `Consultez votre répertoire de téléchargement`,
  },

  registerSpecialOffer: {
    title: `Réservez des places`,
    presentation: `Remplissez le formulaire ci-dessous pour vous inscrire à ce cours.`,
    firstName: `Prénom`,
    lastName: `Nom`,
    email: `Courriel`,
    addLearner: `Ajouter un participant`,
    submit: `Envoyez la demande`,
    managerContactDetails: 'Coordonnées du gestionnaire',
    participantsContactDetails: 'Coordonnées des participants',
    activitySector: `Votre secteur d'activités`,
    participant: 'Participant',
    atLeastOneLearner: 'Vous devez ajouter au moins un participant',
    noMoreThanTwelveLearners: 'Vous ne pouvez pas ajouter plus de douze participants',
    select: `Secteur d'activités`,
  },
}
