import styled from '@emotion/styled'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { CourseFormContextProvider } from '@components/context/CourseFormContext'
import { DownloadingContextProvider } from '@components/context/DownloadingContext'
import { I18nProvider } from '@components/context/I18nContext'
import { MenuDrawerContextProvider } from '@components/context/MenuDrawerContext'
import { NavDrawerContextProvider } from '@components/context/NavDrawerContext'
import HeaderESI from '@components/page/microsoft-esi/HeaderESI'
import Footer from '@components/revamp/Footer'

import '@src/assets/css/print.css'
import '@src/assets/css/style.css'
import '@src/config/i18n'

import './LayoutMicrosoftESI.css'

const Container = styled.div`
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
  overflow-x: hidden;
`

// eslint-disable-next-line quotes
type ViewProps = React.ComponentPropsWithoutRef<'div'>
interface LayoutMicrosoftESIProps extends ViewProps {
  pageContext: {
    lang: string
    isHome: boolean
    paths: string[]
  }
  pageKey: string
}

const LayoutMicrosoftESI = ({ children, pageContext, pageKey }: LayoutMicrosoftESIProps) => {
  const { i18n } = useTranslation()

  // force i18n language when SSRing pages
  if (typeof window === `undefined`) i18n.changeLanguage(pageContext.lang)

  return (
    <I18nProvider
      pageKey={pageKey}
      lang={pageContext.lang || i18n.language}
      paths={pageContext.paths}
    >
      <NavDrawerContextProvider>
        <MenuDrawerContextProvider>
          <CourseFormContextProvider>
            <DownloadingContextProvider>
              <Container>
                <HeaderESI />
                <main>{children}</main>
                <Footer />
              </Container>
            </DownloadingContextProvider>
          </CourseFormContextProvider>
        </MenuDrawerContextProvider>
      </NavDrawerContextProvider>
    </I18nProvider>
  )
}

export default LayoutMicrosoftESI
