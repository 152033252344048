export default {
  placeholder: `Find a course by keywords`,
  homeTitle: `or search by the topics provided:`,
  ariaLabel: `Search`,
  price: `Price`,
  noCourseFound: `No Result.`,
  searchPlaceholder: 'Enter your keywords',
  durationInDays: `{{count}} day`,
  // eslint-disable-next-line @typescript-eslint/camelcase
  durationInDays_plural: `{{count}} days`,
}
