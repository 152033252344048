import React from 'react'

import { CourseFormContextProvider } from '@components/context/CourseFormContext'
import { DownloadingContextProvider } from '@components/context/DownloadingContext'
import { I18nProvider } from '@components/context/I18nContext'
import { MenuDrawerContextProvider } from '@components/context/MenuDrawerContext'
import { NavDrawerContextProvider } from '@components/context/NavDrawerContext'

import '@src/assets/css/style.css'
import i18n from '@src/config/i18n'

type ViewProps = React.ComponentPropsWithoutRef<'div'>
interface Props extends ViewProps {
  pageContext: {
    lang: string
    isHome: boolean
    paths: string[]
  }
  pageKey: string
}

const LayoutSpecialOffer = ({ children, pageKey, pageContext }: Props) => {
  return (
    <I18nProvider
      pageKey={pageKey}
      lang={pageContext.lang || i18n.language}
      paths={pageContext.paths}
    >
      <NavDrawerContextProvider>
        <MenuDrawerContextProvider>
          <CourseFormContextProvider>
            <DownloadingContextProvider>
              <main className='font-avenir'>{children}</main>
            </DownloadingContextProvider>
          </CourseFormContextProvider>
        </MenuDrawerContextProvider>
      </NavDrawerContextProvider>
    </I18nProvider>
  )
}

export default LayoutSpecialOffer
