/* eslint-disable max-len */
import { graphql, useStaticQuery } from 'gatsby'
import find from 'lodash/find'
import React, { useContext, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSpring, animated } from 'react-spring/web.cjs'

import { NavDrawerContext } from '@components/context/NavDrawerContext'

import emptySvgIcon from '@src/assets/svg/empty.svg'
import i18n from '@src/config/i18n'

type PromoBannerProps = {
  height?: number
}

const PromoBanner: React.FC<PromoBannerProps> = ({ height = 60 }) => {
  const { t } = useTranslation(`promos`)
  const [display, setDisplay] = useState(true)
  const [scrolled, setScrolled] = useState(false)
  const menuDrawerContext = useContext(NavDrawerContext)

  const { promos } = useStaticQuery(graphql`
    query {
      promos: allSanityPromoBanner(filter: { active: { eq: true } }) {
        edges {
          node {
            id
            lang
            link
            linkTitle
            title
            color {
              value
              title
            }
          }
        }
      }
    }
  `)

  if (!promos.edges.length) return null

  const promo = find(promos.edges, (edge) => edge.node.lang === i18n.language)
  if (!promo) return null

  const styles = useSpring({
    opacity: display ? 1 : 0,
    height:
      display && height > 0
        ? scrolled
          ? `${Math.max(50, height * 0.5)}px`
          : `${height}px`
        : `0px`,
  })

  const onScroll = () => {
    if (document.scrollingElement) {
      setScrolled(document.scrollingElement.scrollTop > 0)
    }
  }

  const dismissBanner = () => {
    setDisplay(false)
  }

  useEffect(() => {
    document && document.addEventListener(`scroll`, onScroll)
    return function cleanup() {
      document && document.removeEventListener(`scroll`, onScroll)
    }
  }, [])

  useEffect(() => {
    if (menuDrawerContext.opened) {
      dismissBanner()
    }
  }, [menuDrawerContext])

  return (
    <animated.div
      className='sticky top-0 text-black bg-brandColor px-2 z-50'
      style={{
        ...styles,
        backgroundColor: promo.node.color.value,
      }}
    >
      <div className='flex justify-center items-center h-full overflow-hidden'>
        <div className='text-center px-8 md:px-0'>
          <h3 className='text-sm leading-tight'>
            <strong className='md:text-base'>{promo.node.title}</strong>
          </h3>
          {promo.node.link && promo.node.linkTitle && (
            <p className='text-sm leading-tight mt-1 md:mt-0'>
              <a className='text-black hover:text-white underline' href={promo.node.link}>
                {promo.node.linkTitle}
              </a>
            </p>
          )}
        </div>
      </div>
      {display && (
        <animated.div
          className='fixed overflow-y-hidden z-50'
          style={{ top: `0.75vw`, right: `1vw`, ...styles }}
        >
          <a
            className='cursor-pointer block text-center p-1 text-black hover:text-black hover:opacity-100 opacity-25'
            onClick={() => dismissBanner()}
          >
            <img src={emptySvgIcon} className='w-5 cursor-pointer' aria-label={t(`common:close`)} />
          </a>
        </animated.div>
      )}
    </animated.div>
  )
}

export default PromoBanner
