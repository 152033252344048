const get = require(`lodash/get`)

const componentEnPaths = {
  'component---src-pages-recherche-tsx': `search`,
  'component---src-pages-signature-courriel-tsx': `email-signature`,
  'component---src-pages-aws-formation-certification-tsx': `aws-training-certification`,
  'component---src-pages-gestion-de-projet-tsx': `project-management`,
  'component---src-pages-programmes-developpement-leadership-tsx': `leadership-development-programs`,
  'component---src-pages-programmes-tsx': `programs`,
  'component---src-pages-cercles-tsx': `circles`,
  'component---src-pages-coaching-professionnel-tsx': `professional-coaching`,
  'component---src-pages-organisations-tsx': `organizations`,
  'component---src-pages-infolettre-tsx': `newsletter`,
  'component---src-pages-vivre-afiu-tsx': `afiu-experience`,
}

const pagePathFactory = (locale, node) => {
  let sectionSlug = get(node, [`section`, `slug`, locale, `current`], ``)
  if (sectionSlug) sectionSlug += `/`
  return `/${locale}/${sectionSlug}${node.slug[locale].current}`
}

const MSFT_ESI_SLUG = `microsoft-enterprise-skills-initiative`

module.exports = {
  en: {
    aboutUsPath: '/en/about/about-us',
    homePath: `/en`,
    blogPath: `/en/resources/publications`,
    blogPathFactory: (slug) => `/en/publications/${slug}`,
    careersPath: `/en/about/careers`,
    catalogPath: `/en/training/catalog`,
    circlesPath: `/en/circles`,
    confidentialityPath: `/en/confidentiality`,
    faqPath: `/en/about/faq`,
    fundingPath: `/en/solutions/funding-programs`,
    htmlLang: `en`,
    locale: `en-CA`,
    masterClassPathFactory: (slug) => `/en/masterclass/${slug}`,
    microprogramPathFactory: (slug) => `/en/microprograms/${slug}`,
    microsoftEsiIndexSlug: `develop-your-azure-cloud-skills-and-knowledge`,
    microsoftEsiPathFactory: (slug) => `/en/${MSFT_ESI_SLUG}/${slug}`,
    ogLang: `en_CA`,
    organizationsPath: `/en/organizations`,
    ourOfficesPath: `/en/a-propos/nouveaux-bureaux`,
    packagesPromotionsPath: `/en/solutions/packages-promotions`,
    pagePathFactory: (node) => pagePathFactory(`en`, node),
    partnersCertificationsPath: `/en/about/partners-certifications`,
    path: `en`,
    pathFactory: (slug) => `/en/${slug}`,
    programPathFactory: (slug) => `/en/programs/${slug}`,
    programsPath: `/en/programs`,
    publicationIndexPath: `/en/resources/publications/`,
    publicationPathFactory: (slug) => `/en/publications/${slug}`,
    safireKey: `English`,
    teamPath: `/en/a-propos/equipe-et-formateurs`,
    themePathFactory: (slug) => `/en/themes/${slug}`,
    themesPath: `/en/themes/coaching`,
    title: `English`,
    trainerPathFactory: (slug) => `/en/trainers/${slug}`,
    trainingPathFactory: (slug) => `/en/training/${slug}`,
    specialOfferPathFactory: (brand) => `/en/offer/${brand}`,
    specialOfferCoursePathFactory: (brand, slug) => `/en/offer/${brand}/course/${slug}`,
    vivreAfiUPath: `/en/afiu-experience`,
    ...componentEnPaths,
  },
  fr: {
    aboutUsPath: '/fr/a-propos/qui-sommes-nous',
    homePath: `/fr`,
    blogPath: `/fr/ressources/publications`,
    blogPathFactory: (slug) => `/fr/publications/${slug}`,
    careersPath: `/fr/a-propos/carrieres`,
    catalogPath: `/fr/formation/catalogue`,
    circlesPath: `/fr/cercles`,
    confidentialityPath: `/fr/confidentialite`,
    default: true,
    faqPath: `/fr/a-propos/faq`,
    fundingPath: `/fr/solutions/programmes-de-subvention`,
    htmlLang: `fr`,
    locale: `fr-CA`,
    masterClassPathFactory: (slug) => `/fr/masterclass/${slug}`,
    microprogramPathFactory: (slug) => `/fr/microprogrammes/${slug}`,
    microsoftEsiIndexSlug: `developpez-vos-competences-et-vos-connaissances-du-cloud-azure`,
    microsoftEsiPathFactory: (slug) => `/fr/${MSFT_ESI_SLUG}/${slug}`,
    ogLang: `fr_CA`,
    organizationsPath: `/fr/organisations`,
    ourOfficesPath: `/fr/a-propos/nouveaux-bureaux`,
    packagesPromotionsPath: `/fr/solutions/forfaits-promotions`,
    pagePathFactory: (node) => pagePathFactory(`fr`, node),
    partnersCertificationsPath: `/fr/a-propos/partenaires-certifications`,
    path: `fr`,
    pathFactory: (slug) => `/fr/${slug}`,
    programsPath: `/fr/programmes`,
    programPathFactory: (slug) => `/fr/programmes/${slug}`,
    publicationIndexPath: `/fr/ressources/publications/`,
    publicationPathFactory: (slug) => `/fr/publications/${slug}`,
    safireKey: `French`,
    themePathFactory: (slug) => `/fr/thematiques/${slug}`,
    themesPath: `/fr/thematiques/coaching`,
    title: `Français`,
    trainerPathFactory: (slug) => `/fr/formateurs/${slug}`,
    trainingPathFactory: (slug) => `/fr/formation/${slug}`,
    teamPath: `/fr/a-propos/equipe-et-formateurs`,
    specialOfferPathFactory: (brand) => `/fr/offre/${brand}`,
    specialOfferCoursePathFactory: (brand, slug) => `/fr/offre/${brand}/formation/${slug}`,
    vivreAfiUPath: `/fr/vivre-afiu`,
  },
}
