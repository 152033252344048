import styled from '@emotion/styled'
import isNil from 'lodash/isNil'
import React from 'react'
import { useTranslation } from 'react-i18next'

import PromoBanner from '@components/common/PromoBanner'
import { CourseFormContextProvider } from '@components/context/CourseFormContext'
import { DownloadingContextProvider } from '@components/context/DownloadingContext'
import { I18nProvider } from '@components/context/I18nContext'
import { MenuDrawerContextProvider } from '@components/context/MenuDrawerContext'
import { NavDrawerContextProvider } from '@components/context/NavDrawerContext'
import LayoutBlank from '@components/layout/LayoutBlank'
import LayoutSpecialOffer from '@components/page/microsites/LayoutSpecialOffer'
import LayoutMicrosoftESI from '@components/page/microsoft-esi/LayoutMicrosoftESI'
import FooterRevamp from '@components/revamp/Footer'

import '@src/assets/css/print.css'
import '@src/assets/css/style.css'
import '@src/config/i18n'

/* custom element for brand bar: see gatsby-browser */
/* eslint-disable @typescript-eslint/no-namespace, quotes */
declare global {
  namespace JSX {
    interface IntrinsicElements {
      'edgenda-group-brand-bar': any
    }
  }
}
/* eslint-enable quotes */

const Container = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`

type pageContextType = {
  lang: string
  altLayout?: string
  isHome: boolean
  isSearch: boolean
  useDarkHeader: boolean
  paths: string[]
  genericPagePath: string
  noBaseHeader?: boolean
}

type LayoutProps = {
  children: React.ReactElement
  readonly pageContext: pageContextType
}

const Layout: React.FC<LayoutProps> = ({ children, pageContext }) => {
  const { i18n } = useTranslation()

  // force i18n language when SSRing pages
  if (typeof window === `undefined`) i18n.changeLanguage(pageContext.lang)

  if (!isNil(pageContext.altLayout)) {
    if (pageContext.altLayout === `blank`) {
      return (
        <LayoutBlank pageContext={pageContext} pageKey={children.key as string}>
          {children}
        </LayoutBlank>
      )
    }
    if (pageContext.altLayout === `microsoft-esi`) {
      return (
        <LayoutMicrosoftESI pageContext={pageContext} pageKey={children.key as string}>
          {children}
        </LayoutMicrosoftESI>
      )
    }

    if (pageContext.altLayout === `microsite`) {
      return (
        <LayoutSpecialOffer pageContext={pageContext} pageKey={children.key as string}>
          {children}
        </LayoutSpecialOffer>
      )
    }
  }

  return (
    <I18nProvider
      pageKey={children.key as string}
      lang={pageContext.lang || i18n.language}
      paths={pageContext.paths}
    >
      <NavDrawerContextProvider>
        <MenuDrawerContextProvider>
          <CourseFormContextProvider>
            <DownloadingContextProvider>
              <PromoBanner />
              <Container>
                <>
                  <main className='font-avenir text-black'>
                    {children}
                    <FooterRevamp />
                  </main>
                </>
              </Container>
            </DownloadingContextProvider>
          </CourseFormContextProvider>
        </MenuDrawerContextProvider>
      </NavDrawerContextProvider>
    </I18nProvider>
  )
}

export default Layout
