import { Link } from 'gatsby'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { FooterColumnData } from './Footer'

type Props = {
  column: FooterColumnData
}

export default function FooterColumn({ column }: Props) {
  const { t } = useTranslation('revamp')

  return (
    <div className='text-r-sm flex flex-col gap-3' style={{ width: '220px' }}>
      <h3 className='font-bold'>{column.title}</h3>
      {column.rootUrl && (
        <a href={column.rootUrl} className='font-bold text-revamp-blue text-sm hover:underline'>
          {t('visitWebsite')}
        </a>
      )}
      {column.items.map((item) => (
        <a
          className='text-black text-sm hover:underline hover:text-black'
          key={item.title}
          href={item.href}
        >
          {item.title}
        </a>
      ))}
    </div>
  )
}
