export default {
  close: `Fermer <`,
  moreDetails: `En savoir plus >`,
  firstTenEcho: {
    descTitle: `Offre spéciale`,
    descShort: `Participez à une formation AWS confirmée et repartez avec une enceinte Echo d’Amazon.`,
    descLongOne: `LES 10 PREMIERS PARTICIPANTS se verront remettre une enceinte Echo d’Amazon.`,
    descLongTwo: `Faites partie des 10 premiers participants à une de nos formations confirmées AWS et repartez avec plus que de nouvelles compétences.`,
    descLongThree: `Qu’est-ce qu’une Echo ?`,
    descLongFour: `Il s’agit d’une enceinte mains libres contrôlée par la voix. Elle se connecte au service vocal Alexa pour écouter de la musique, poser des questions, faires des appels, envoyer et recevoir des messages, donner de l’information, entendre les nouvelles et les résultats sportifs, les prévisions météo et plus encore.`,
    linkUrl: `/fr/formation/catalogue/aws`,
    linkLabel: `Inscrivez-vous à une formation confirmée dès maintenant >`,
    rulesUrl: `/fr/promo/aws-enceinte-echo/reglement-du-concours`,
    rulesLabel: `Règlement du concours >`,
  },
  microsoftESI: {
    pageTitle: `Développez vos compétences et vos connaissances`,
    pageIntro: `À titre de partenaire officiel du programme Enterprise Skills Initiative de Microsoft, Afi\xa0U. est fière de vous aider à accélérer votre transformation et l’atteinte de vos objectifs en facilitant le développement de vos compétences. Nos formateurs-experts vous transfèreront les compétences et les connaissances nécessaires pour tirer rapidement profit de la plateforme et des services Azure et Intelligence artificielle de Microsoft.`,
    listTitle: `Liste officielle des formations proposées`,
    headerHome: `Technical Skills <br />for Business`,
    header: `Enterprise Skills Initiative`,
    view: `Voir`,
    back: `Retour aux formations proposées`,
    price: `Prix`,
    yourAdvisor: `Votre conseiller`,
    yourAdvisorFemale: `Votre conseillère`,
    distinctionAfiU: `La distinction Afi\xa0U.`,
    distinctionAfiUList: [
      'Entreprise basée au Canada (avec siège social au Québec)',
      'Formation en présentiel ou en virtuel, en direct',
      'Équipe interne de formateurs Azure et AI certifiés',
      'Formations disponibles en français ou en anglais',
    ],
    requestInformation: "Demande d'informations ou d'inscription aux formations",
    ctaLink: 'https://lp.afiexpertise.com/contactez-nous-esi',
  },
  virtual: {
    descTitle: `Code promo VIRT40`,
    descShort: `Profitez d’un rabais de 40% sur nos sessions garanties en classe virtuelle.`,
    linkUrl: `/fr/formation/catalogue`,
  },
  subvention: {
    descTitle: `Programme de formation financé jusqu’à 50%`,
    descShort: ``,
    linkLabel: `Voir les subventions applicables`,
    linkUrl: `/fr/solutions/programmes-de-subvention`,
  },
}
