import React from 'react'

import { I18nProvider } from '@components/context/I18nContext'

import '@src/assets/css/style.css'
import i18n from '@src/config/i18n'

type ViewProps = React.ComponentPropsWithoutRef<'div'>
interface Props extends ViewProps {
  pageContext: {
    lang: string
    isHome: boolean
    paths: string[]
  }
  pageKey: string
}

const LayoutBlank = ({ children, pageKey, pageContext }: Props) => {
  return (
    <I18nProvider
      pageKey={pageKey}
      lang={pageContext.lang || i18n.language}
      paths={pageContext.paths}
    >
      <main className='font-avenir'>{children}</main>
    </I18nProvider>
  )
}

export default LayoutBlank
