export default {
  featured: {
    action: `View`,
  },
  headerTitle: `Never stop Learning`,
  imageLegend: {
    chouakri: `VP Emerging Technologies, Coach and Trainer`,
    demers: `VP, Coach and Leadership Trainer`,
    mangones: `Trainer, Coach and Senior Consultant`,
    paradis: `Microsoft Certified Trainer and IT Consultant`,
  },
  partnersCertifications: {
    title: `AFI, official partner of the market leaders`,
    text: `AFI is endorsed as an approved course provider with many professional accrediting bodies.`,
    cta: `View all our partners`,
  },
  readMore: `Read more`,
  testimonials: `What I enjoyed most about my training`,
}
